<!-- china B2B 订单列表 -->
<template>
	<div class="app-container" v-loading.fullscreen="uploadloading" :element-loading-text="$t('tipsInfo.uploading')"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<!-- B2B空运订单 -->
			<el-tab-pane name="first">
				<span slot="label">
					<i class="el-icon-s-promotion"></i>
					{{$t('B2BOrder.airTransportOrder')}}
				</span>
				<div class="listHeader">
					<i></i><span>{{$t('B2BOrder.airTransportOrderList')}}</span>
				</div>
				<el-form style="margin:10px 0 0 0" :model="queryParams" ref="queryParams" :inline="true"
					label-width="auto" @submit.native.prevent>
					<el-form-item label prop="orderNo">
						<el-input v-model.trim="queryParams.orderNo" clearable
							:placeholder="$t('commonInfo.orderNoColumn')" style="width: 200px;"></el-input>
					</el-form-item>
					<el-form-item label prop="">
						<el-date-picker v-model="orderTime" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="-" :start-placeholder="$t('commonInfo.beginTime')"
							:end-placeholder="$t('commonInfo.endTime')" style="width:300px"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-search" type="primary" @click="handleSearch">
							{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button icon="el-icon-refresh" type="primary" @click="getlist">
							{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}
						</el-button>
						<el-button icon="el-icon-plus" type="primary" @click="addOrder">
							{{$t('commonInfo.createAirOrder')}}</el-button>
					</el-form-item>
					<!-- <el-form-item>
						<el-button icon="el-icon-plus" type="primary" @click="batchCreateOrder">
							{{$t('commonInfo.batchCreateAirOrder')}}</el-button>
					</el-form-item> -->
				</el-form>
				<el-table :data="tableData" ref="b2bOrderTable" border style="width: 100%;font-size: 16px;"
					v-loading="loading" :row-key="rowKey" :expand-row-keys="expandRowArray"
					:row-class-name="setClassName">
					<el-table-column type="expand" label="" align="center" width="1">
						<template slot-scope="{row}">
							<el-row class="expandDataRow" v-for="subOrder in row.subOrderList" :key="subOrder.id"
								type="flex" justify="center" align="middle">
								<el-col :span='3'>
									<img style="width: 100px;height: 100px; margin: 0px auto; display: block;"
										src="../../assets/images/subOrder.png" />
								</el-col>
								<el-col :span="21">
									<el-descriptions :column="2" border
										:labelStyle="{'width':'160px','text-align':'right','font-weight':'bold'}">
										<el-descriptions-item :label="$t('commonInfo.batch')">{{subOrder.batchCount}}
										</el-descriptions-item>
										<el-descriptions-item :label="$t('commonInfo.packageAmount')">
											{{subOrder.packageAmount}}</el-descriptions-item>
										<el-descriptions-item :label="$t('commonInfo.time')">{{subOrder.initTime}}
										</el-descriptions-item>
										<el-descriptions-item :label="$t('commonInfo.statusColumn')">{{subOrder.memo}}
										</el-descriptions-item>
									</el-descriptions>
								</el-col>
							</el-row>
						</template>
					</el-table-column>

					<el-table-column :label="$t('commonInfo.orderNoColumn')" prop="esnadTobOrderNo" min-width="160"
						align="center"></el-table-column>
					<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" prop="clientOrderNo" min-width="160"
						align="center">
						<template slot-scope="{row}">
							<span>{{row.clientOrderNo==''||row.clientOrderNo==null?$t('commonInfo.noInfo'):row.clientOrderNo}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('commonInfo.packageAmount')" prop="packageAmount" min-width="100"
						align="center">
						<template slot-scope="scope">{{ scope.row.packageAmount }}</template>
					</el-table-column>
					<el-table-column :label="$t('commonInfo.deliveryWarehouse')" prop="dest" min-width="160"
						align="center">
						<template slot-scope="scope">
							{{ scope.row.dest == "custom"?$t('commonInfo.customeWarehouse'):scope.row.dest }}
						</template>
					</el-table-column>
					<!-- <el-table-column :label="$t('chinaWhB2BOrderPage.customsDeclarationFlag')" align="center" >
						<template slot-scope="{row}">
							{{row.customsDeclarationFlag?$t('commonInfo.yesBtn'):$t('commonInfo.noBtn')}}
						</template>
					</el-table-column> -->
					<el-table-column :label="$t('commonInfo.statusColumn')" min-width="120" align="center">
						<template slot-scope="scope">
							<div>
								<span style="display: block;">{{returnStatusName(scope.row)}}</span>
								<div v-if="isShowPod(scope.row)">
									<el-button type="text" icon="el-icon-picture" style="height: 22px; padding: 0px;"
										size="small" @click="checkPOD(scope.row.podLink)">
										{{$t('commonInfo.actionCheckPOD')}}</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('B2BOrder.examineStatus')" align="center" min-width="120"
						prop="examineFlag">
						<template slot-scope="{row}">
							<span>{{row.examineFlag?$t('chinaWhB2BOrderPage.Approved'):$t('chinaWhB2BOrderPage.underReview')}}</span>
						</template>
					</el-table-column>
					<el-table-column :label="$t('commonInfo.initTime')" prop="initTime" align="center" min-width="180">
					</el-table-column>
					<el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width"
						fixed="right" width="120" align="center" style="padding-right:0">
						<template slot-scope="{ row }">
							<!-- <el-button type="text" size="small" @click="edit(row)" v-if="isEditOrder(row)" style="margin-left:10px" icon="el-icon-edit-outline">{{$t('commonInfo.modifyOrder')}}
								</el-button> -->
							<el-button type="text" size="small" @click="handleCheckPackageInfo(row)"
								style="margin-left:10px" icon="el-icon-box">{{$t('chinaWhB2COrderPage.squareWeighing')}}
							</el-button>
							<!-- <el-button type="text" size="small" @click="editFBA(row)" icon="el-icon-edit" v-if="isEditFBA(row)">{{$t('chinaWhB2BOrderPage.actionModifyFBA')}}
								</el-button> -->
							<el-button type="text" size="small" @click="xiang(row.esnadTobOrderNo)"
								icon="el-icon-s-promotion">{{$t('commonInfo.actiontrack')}}</el-button>
							<el-button v-if="isShowPDF(row) && row.waybillLink != null" type="text" size="small"
								@click="PDFSheet(row.waybillLink)" icon="el-icon-download">
								{{$t('commonInfo.downloadBoxStickers')}}
							</el-button>
							<el-button size="mini" v-else type="text" disabled icon="el-icon-loading">
								{{$t('commonInfo.boxStickersGenerating')}}</el-button>
							<!-- <el-button type="text" size="small" @click="FBAsheet(row.skuListLink)" icon="el-icon-download">{{$t('commonInfo.actionDownloadSkuList')}}</el-button> -->
							<!-- <el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot class="item batchInfoItem">
								<el-button type="text" size="small" @click="handleExpandRow(row)" :icon="row.expand?'el-icon-arrow-up':'el-icon-arrow-down'">{{$t('chinaWhB2BOrderPage.batchTrackingTitle')}}</el-button>
								</el-badge> -->
							<!-- <el-button type="danger" size="small" @click="del(row.esnadTobOrderNo)">删除订单</el-button> -->
							<el-dropdown style="margin-left: 10px;" @command="handleCommand">

								<span style="font-size: 12px; color: #409EFF;"
									class="el-dropdown-link">{{$t('commonInfo.moreAction')}}<i
										class="el-icon-arrow-down el-icon--right"></i></span>
								<el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot
									class="item">
								</el-badge>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-if="isEditOrder(row)" :command="{'command':'a','row':row}">
										{{ $t('commonInfo.modifyOrder') }}</el-dropdown-item>
									<el-dropdown-item v-if="isEditFBA(row)" :command="{'command':'b','row':row}">
										{{ $t('chinaWhB2BOrderPage.actionModifyFBA') }}</el-dropdown-item>
									<el-dropdown-item :command="{'command':'c','row':row}">
										{{ $t('commonInfo.actionDownloadSkuList') }}</el-dropdown-item>
									<el-badge v-if="row.subOrderList != null && row.subOrderList.length > 0" is-dot
										class="item batchInfoItem1">
										<el-dropdown-item :command="{'command':'d','row':row}">
											{{ $t('chinaWhB2BOrderPage.batchTrackingTitle') }}</el-dropdown-item>
									</el-badge>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</el-table>
				<!-- <el-image-viewer v-if="showImg" :on-close="closeImg" :url-list="imgList" /> -->
				<div class="pageBottom">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="queryParams.page" :page-sizes="[10, 20, 30, 40]" :page-size="queryParams.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-tab-pane>
			<!-- B2B海运订单 -->
			<el-tab-pane name="second">
				<span slot="label">
					<i class="el-icon-ship"></i>
					{{$t('B2BOrder.oceanShippingOrder')}}
				</span>
				<div class="listHeader">
					<i></i><span>{{$t('B2BOrder.oceanShippingOrderList')}}</span>
				</div>
				<el-form style="margin:10px 0 0 20px" :model="seaQueryParams" ref="seaQueryParams" :inline="true"
					label-width="auto" @submit.native.prevent>
					<el-form-item label prop="orderNumber">
						<el-input type="textarea" v-model="orderNumber" row="2"
							:placeholder="$t('commonInfo.orderNoColumn')" clearable size="small"
							style="width:220px;font-size: 16px;" />
					</el-form-item>
					<el-form-item label prop="clientAwbNo">
						<el-input type="textarea" v-model="clientAwbNo" row="2"
							:placeholder="$t('commonInfo.clientOrderNoColumn')" clearable size="small"
							style="width:220px;font-size: 16px;" />
					</el-form-item>
					<el-form-item>
						<el-button icon="el-icon-search" type="primary" @click="handleSearchSea">
							{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button type="primary" icon="el-icon-refresh" @click="getSeaOrderList">
							{{$t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleResetSea">{{$t('commonInfo.dialogBtnReset')}}
						</el-button>
						<el-button icon="el-icon-plus" type="primary" @click="addSeaOrder">
							{{$t('commonInfo.createSeaOrder')}}</el-button>
						<el-button icon="el-icon-download" @click="handleDownloadTemplate">
							{{$t('commonInfo.actionDownloadSeaSkuTemplate')}}</el-button>
					</el-form-item>
				</el-form>
				<el-table v-loading="loadingList" :data="seaOrderList" ref="tableList" border
				  :row-class-name="setSeaOrderClassName"
					style="width: 100%;font-size: 16px;">
					<el-table-column :label="$t('commonInfo.initTime')" align="center" prop="initTime"
						min-width="180px" />
					<el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="awbNo"
						min-width="180px" />
					<el-table-column :label="$t('commonInfo.clientOrderNoColumn')" align="center" prop="clientAwbNo"
						min-width="180px" />
					<el-table-column :label="$t('commonInfo.packageAmount')" align="center" prop="packageAmount"
						min-width="100" />
					<el-table-column :label="$t('B2BOrder.deCountry')" align="center" prop="destCountry"
						min-width="100" />
					<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="100" />
					<el-table-column :label="$t('B2BOrder.examineStatus')" align="center" min-width="120"
						prop="reviewStatus">
						<template slot-scope="{row}">
							<label>{{row.reviewStatus}}</label>
							<div v-if="row.reviewStatus == '审核未通过'">
								<el-button type="text" icon="el-icon-view" style="height: 22px; padding: 0px;"
									size="small" @click="handleCheckSeaOrderFailedReason(row)">
									{{$t('commonInfo.actionCheckReason')}}</el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('commonInfo.statusColumn')" align="center" prop="trackingStatus"
						width="160px" />
					<el-table-column label="POD" align="center" prop="podUrl">
						<template slot-scope="{row}">
							<div v-if="row.podUrlList.length > 0">
								<img :src="row.podUrlList[0]" @click="handlePOD(row.podUrlList)"
									style="width: 60px;height: 60px;" />
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('commonInfo.operationColumn')" class-name="small-padding fixed-width"
						fixed="right" width="120" align="center" style="padding-right:0">
						<template slot-scope="{row}">
							<el-button size="mini" type="text" icon="el-icon-view" style="margin-left:10px"
								@click="orderTracking(row)">{{$t('commonInfo.actiontrack')}}</el-button>
							<el-button size="mini" type="text" icon="el-icon-view" @click="handleView(row)">
								{{$t('commonInfo.actionDetail')}}</el-button>
							<el-button size="mini" type="text" icon="el-icon-view"
								@click="handleCheckOrderDestInfo(row)">{{$t('chinaWhB2BOrderPage.destInfo')}}
							</el-button>
							<el-button size="mini" v-if="row.reviewStatus=='审核通过' && row.awbUrl==''" type="text"
								disabled icon="el-icon-loading">{{$t('commonInfo.awbGenerating')}}</el-button>
							<el-button v-if="row.reviewStatus != '审核通过'" size="mini" type="text" icon="el-icon-edit"
								style="margin-left:10px" @click="handleUpdateOrder(row)">
								{{$t('commonInfo.modifyOrder')}}</el-button>
							<el-button size="mini" v-if="row.reviewStatus=='审核通过' && row.awbUrl!=''" type="text"
								icon="el-icon-download" @click="handleDownloadAwb(row)">{{$t('commonInfo.faceNoodles')}}
							</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div class="pageBottom">
					<el-pagination @size-change="sizeChange" @current-change="currentChange"
						:current-page="seaQueryParams.page" :page-sizes="[10, 20, 30, 40]"
						:page-size="seaQueryParams.limit" layout="total, sizes, prev, pager, next, jumper"
						:total="total"></el-pagination>
				</div>
			</el-tab-pane>
		</el-tabs>
		<div>
			<el-image-viewer style="z-index: 4000;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		</div>

		<!-- 查看订单箱信息 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.dialogPackageInfoTitle')" :visible.sync="packageDialogPopup"
			width="1200px" :close-on-click-modal="false">
			<el-row>
				<label
					style="padding: 20px;display: inline-block;font-size: 18px;">{{$t('commonInfo.orderNoColumn') + ':'}}</label><span
					style="padding: 20px;display: inline-block; font-size: 18px;">{{checkPackageOrderNo}}</span>
			</el-row>
			<el-table :data="packageData" v-loading="packageListLoading" show-summary :summary-method="getSummaries">
				<el-table-column prop="clientLgPackageNo" :label="$t('commonInfo.packageNo')" width="180"
					align="center">
				</el-table-column>
				<el-table-column prop="esnadLgPackageNo" :label="$t('commonInfo.dlvdPackageNo')" width="180"
					align="center">
				</el-table-column>
				<el-table-column prop="subOrderNo" :label="$t('commonInfo.batchNo')" align="center" width="140">
				</el-table-column>
				<el-table-column prop="length" :label="$t('commonInfo.length') + '(CM)'" width="80" align="center">
				</el-table-column>
				<el-table-column prop="breadth" :label="$t('commonInfo.breadth') + '(CM)'" width="80" align="center">
				</el-table-column>
				<el-table-column prop="height" :label="$t('commonInfo.height') + '(CM)'" width="80" align="center">
				</el-table-column>
				<el-table-column prop="grossWeight" :label="$t('commonInfo.grossWeight') + '(KG)'" width="80" align="center">
				</el-table-column>
				<el-table-column prop="retreatWeight" :label="$t('commonInfo.retreatWeight') + '(KG)'" width="95" align="center">
				</el-table-column>
				<el-table-column prop="chargedWeight" :label="$t('commonInfo.chargeWeight') + '(KG)'" width="95" align="center">
				</el-table-column>
				<el-table-column prop="productAmount" :label="$t('commonInfo.productAmount')" width="110"  align="center">
				</el-table-column>
				<el-table-column prop="picUrl" min-width="100" :label="$t('commonInfo.photo')" align="center">
					<template slot-scope="{row}">
						<div v-if="row.picUrl != null && row.picUrl != ''">
							<el-button type="text" @click="handleViewCartonImg(row.picUrl)" icon="el-icon-view">
								{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</div>
						<span v-else></span>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<!-- 添加订单窗口 -->
		<el-dialog :title="orderTitle" :visible.sync="orderPopup" width="840px" append-to-body
			:close-on-click-modal="false" @close="handleDialogClose">
			<el-form ref="orderForm" :model="form" :inline="true" :rules="rules" style="min-height: 300px;"
				label-width="150px">
				<el-form-item :label="$t('commonInfo.deliveryCountry')" prop="destCountry">
					<el-select v-model="form.destCountry"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryCountry')"
						style="width: 590px;">
						<el-option v-for="item in countryList" :key="item.value" :label="item.name" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-show="showOne" :label="$t('commonInfo.deliveryType')" prop="warehouseType">
					<el-select v-model="form.warehouseType"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryType')"
						style="width: 590px;">
						<el-option v-for="item in warehouseTypeList" :key="item.value" :label="item.name"
							:value="item.value"></el-option>
					</el-select>
					<!-- <span v-show="isSa" class="SaTip">{{tipInfo}}</span> -->
				</el-form-item>
				<!-- 自提不需要显示仓库和地址，默认飞坦海外仓，需要填写联系人和联系方式 2022-4-12 -->
				<el-form-item v-if="showType" :label="$t('commonInfo.deliveryMode')" prop="deliveryType">
					<el-radio v-model="form.deliveryType" label="1">{{$t('commonInfo.homeDeliveryService')}}</el-radio>
					<el-radio v-model="form.deliveryType" label="2">{{$t('commonInfo.whSelfPickup')}}</el-radio>
				</el-form-item>
				<el-form-item v-if="returnShowDestWh()" :label="$t('commonInfo.deliveryWarehouse')" prop="destWhNo">
					<el-select v-model="form.destWhNo" filterable
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')"
						style="width: 590px;">
						<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
							:value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<div v-show="showThree">
					<el-form-item v-if="returnShowOtherDest()" :label="$t('commonInfo.deliveryDest')" prop="otherDest">
						<el-input style="width: 590px;" v-model="form.otherDest" :disabled="editOtherDest"
							:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.deliveryDest')"></el-input>
					</el-form-item>
					<el-form-item v-if="form.destCountry == 'SA' && form.warehouseType == '5'"
						:label="$t('commonInfo.city')" prop="destCity">
						<el-select v-model="form.destCity" :disabled="editCity"
							:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.city')"
							style="width: 590px;">
							<el-option v-for="item in saCityList" :key="item.dictCode" :label="item.dictLabel"
								:value="item.dictLabel"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-if="form.warehouseType == '1'" :label="$t('commonInfo.fbaNo')" prop="fbaNo">
						<el-input v-model="form.fbaNo" :placeholder="$t('commonInfo.FBATips')" style="width: 215px">
						</el-input>
					</el-form-item>

					<el-form-item v-if="form.warehouseType == '1'" :label="$t('commonInfo.fbaNoCode')" prop="fbaNoCode">
						<el-input v-model="form.fbaNoCode" style="width: 215px"
							:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNoCode')"></el-input>
					</el-form-item>
					<!-- 预约送仓时间 -->
					<el-form-item v-if="form.warehouseType == '2'" :label="$t('commonInfo.deliveryDate')"
						prop="deliveryDate">
						<el-date-picker clearable style="width: 590px;" v-model="form.deliveryDate"
							:picker-options="deliveryPickerOptions" type="date" value-format="yyyy-MM-dd"
							:placeholder="$t('chinaWhB2BOrderPage.deliveryDateTips')"></el-date-picker>
					</el-form-item>
					<!-- 预约信息 注意：该字段与上传PDF文件绑定，填写或者上传某一个，则另一个必填-->
					<el-form-item v-if="form.warehouseType == '2'" :label="$t('commonInfo.asnNo')" prop="asnNo">
						<el-input v-model="form.asnNo" style="width: 590px"
							:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.asnNo')"></el-input>
					</el-form-item>
					<!-- 选择自定义仓库时 需要必填联系人和联系电话 -->
					<el-form-item
						:label="form.deliveryType=='2'?$t('commonInfo.consigneeTypeOne'):$t('commonInfo.contactMan')"
						v-if="form.warehouseType == '5' && showContactInfo" prop="contacts">
						<el-input v-model="form.contacts" style="width: 215px" :disabled="editContactInfo"
							:placeholder="form.deliveryType=='2'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.consigneeTypeOne'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contactMan')">
						</el-input>
					</el-form-item>
					<el-form-item
						:label="form.deliveryType=='2'?$t('commonInfo.consigneeContact'):$t('commonInfo.contact')"
						v-if="form.warehouseType == '5' && showContactInfo" prop="contactsTel">
						<el-input v-model="form.contactsTel" style="width: 215px" :disabled="editContactInfo"
							:placeholder="form.deliveryType=='2'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.consigneeContact'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contact')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('commonInfo.declaredValue')" prop="declaredValue">
						<el-input v-model.number="form.declaredValue" style="width: 215px"
							:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.declaredValue')">
							<span slot="suffix" style="margin-right: 5px;">USD</span>
						</el-input>
					</el-form-item>
					<!-- 包裹总件数和商品总件数公用一个字段 -->
					<el-form-item
						:label="form.warehouseType=='6'?$t('commonInfo.packageCount'):$t('commonInfo.skuCount')"
						prop="skuCount">
						<el-input v-model.number="form.skuCount"
							:style="{'width': form.warehouseType == '1'?'185px':'215px'}"
							:placeholder="form.warehouseType=='6'?$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageCount'):$t('tipsInfo.inputPlaceholder') + $t('commonInfo.skuCount')">
						</el-input>
						<!-- 选择FBA订单提示 -->
						<el-tooltip v-if="form.warehouseType == '1'" class="item" effect="dark"
							:content="$t('chinaWhB2BOrderPage.orderSkuCountTips')" placement="top-start">
							<i style="font-size: 22px; margin-left: 10px; color: #c7924c;" class="el-icon-warning"></i>
						</el-tooltip>
					</el-form-item>
					<!-- 是否报关退税 -->
					<el-form-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')" prop="customsDeclarationFlag">
						<el-radio style="margin-right: 40px;" v-model="form.customsDeclarationFlag" :label="true">
							{{$t('chinaWhB2BOrderPage.officialCD')}}</el-radio>
						<el-radio v-model="form.customsDeclarationFlag" :label="false">
							{{$t('chinaWhB2BOrderPage.unofficialCD')}}</el-radio>
					</el-form-item>
					<!-- 客户订单号 必填 根据客户信息设置判断是否显示 -->
					<el-form-item v-if="isSpecial" :label="$t('chinaWhB2BOrderPage.clientOrderNo')"
						prop="clientOrderNo">
						<el-input v-model="form.clientOrderNo" style="width: 215px"
							:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.clientOrderNo')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('chinaWhB2BOrderPage.kindnameDes')">
						<el-select v-model="kindName" value-key="id" multiple filterable
							:placeholder="$t('chinaWhB2BOrderPage.kindnameSelectTips')" style="width: 470px;">
							<el-option v-for="item in kindNameList" :key="item.id" :label="item.label"
								:value="item.value">
								<span style="float: left">{{ item.productChineseDesc }}</span>
								<span
									style="float: right; margin-right: 20px; color: #8492a6; font-size: 13px">{{ item.productEnglishDesc }}</span>
							</el-option>
						</el-select>
						<!-- <span class="productTip">若该订单包含5种以上产品，建议填写至少5种主要产品。若该订单少于5种产品，则需一一填写。</span> -->
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="small" @click="openKindName" style="margin-left: 10px;"
							icon="el-icon-plus">
							{{$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')}}
						</el-button>
					</el-form-item>
				</div>
			</el-form>
			<el-card v-show="showThree" class="box-card" v-loading="caseLoading">
				<div slot="header" class="clearfix">
					<span>{{$t('chinaWhB2BOrderPage.clientOrderPackageAmount')}}</span>
					<div style="display: inline-block;" v-if="!isEdit">
						<el-input v-model.number="caseNum" style="width: 120px; margin-left: 20px;" type="tel"
							@input="handleNum()"></el-input>
						<span
							style="margin-left: 10px; color: #bdbdbd;">{{$t('chinaWhB2BOrderPage.createBoxNoTips')}}</span>
					</div>
					<span style="margin-left: 10px;" v-else>{{caseNum}}</span>
				</div>
				<!-- 超过十条数据隐藏 -->
				<el-collapse v-show="caseNum > 0" v-model="showCaseDetail">
					<el-collapse-item :title="'已生成 ' + caseNum + ' 箱 , 点击展开/隐藏'" name="1">
						<div v-if="isCaseNormal">
							<el-row style="margin: 20px 0;" v-for="(item, index) in caseData" :key="index">
								<el-col style="width: 107px;margin-right: 12px;">
									<span
										style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
								</el-col>
								<el-col :span="10">
									<el-input v-model="item.input" style="font-size: 16px;" disabled />
								</el-col>
								<el-col :span="8" v-if="isEdit">
									<el-button v-show="index == (caseData.length - 1)" size="medium" type="text"
										icon="el-icon-circle-plus"
										style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
										@click="addCaseItem(item)" />
									<el-button size="medium" type="text" icon="el-icon-delete"
										style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
										@click="delCaseItem(item,index)" />
								</el-col>
							</el-row>
						</div>
						<div v-if="isCaseSpecial">
							<el-row v-for="(item,index) in caseDataCustom" :key="index"
								:style="{padding: '10px 0',backgroundColor:item.color}">
								<el-col style="width: 107px;margin-right: 12px;">
									<span
										style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;width: 100%;font-size: 16px;">{{$t('commonInfo.packageNo')}}</span>
								</el-col>
					 		<el-col :span="12">
									<span
										style="display: inline-block;height: 40px; text-align: right;padding: 0 12px 0 0; line-height: 40px;font-size: 16px;">{{item.preNum}}</span>
									<el-input v-model="item.input" maxlength="20" @input="handleInputCustomCase()"
										style="font-size: 16px;width: 200px;">
										<template slot="suffix">
											<el-tooltip v-if="item.input != ''" class="item" effect="dark"
												:content="$t('chinaWhB2BOrderPage.caseAutoFillTips')"
												placement="top-start">
												<i class="el-input__icon el-icon-document"
													@click="autoFillCaseInfo(item,index)"></i>
											</el-tooltip>
										</template>
									</el-input>
									<span
										style="display: inline-block;height: 40px; text-align: right;padding: 0 0 0 12px; line-height: 40px;font-size: 16px;">{{item.nextNum}}</span>
								</el-col>
								<el-col :span="6" v-if="isEdit">
									<el-button v-show="index == (caseDataCustom.length - 1)" size="medium" type="text"
										icon="el-icon-circle-plus"
										style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
										@click="addCustomCaseItem(item)" />
									<el-button size="medium" type="text" icon="el-icon-delete"
										style="margin-left:10px;font-size: 28px;height: 40px;padding: 0px;"
										@click="delCaseCustomItem(item,index)" />
								</el-col>
							</el-row>
						</div>
					</el-collapse-item>
				</el-collapse>

			</el-card>
			<div v-show="showThree" style="margin: 10px 0;height: 60px;">
				<SingleUploadPDF v-model="fbxFlienName" v-show="form.warehouseType == '1'"
					:tipText="$t('commonInfo.fbxFileTips')" :preFile="fbxPreName" @input="getFBXInfo"
					style="width: 120px;float: left;margin-left: 20px;">
				</SingleUploadPDF>
				<!-- 上传PDF附件 选择noon显示 -->
				<SingleUploadPDF v-show="form.warehouseType == '2'" v-model="pdfFlienName"
					:tipText="$t('commonInfo.pdfFileTips')" :preFile="pdfPreName" @input="getPDFInfo"
					style="width: 120px;float: left;margin-left: 20px;">
				</SingleUploadPDF>
				<!-- 上传清单附件 -->
				<SingleUpload v-show="form.warehouseType != '6'" v-model="filename" @input="getExcel"
					:tipText="$t('commonInfo.skuFileTips')" :preFile="fileTXTName" :orderNo="form.orderNo"
					style="float: left;margin-left:30px;width:152px"></SingleUpload>
				<!-- 模板文件下载，分类型 -->
				<el-button size="small" style="float: left;" icon="el-icon-download" @click="handleDownloadAirTemplate">
					{{$t('commonInfo.downloadTemplate')}}</el-button>
				<!-- 上传分拣送货清单附件 -->
				<SingleUpload v-model="sortingFilename" v-show="isSorting" @input="getSortingExcel"
					:tipText="$t('commonInfo.sortSkuFileTips')" :preFile="sorttingFileTXTName" :orderNo="form.orderNo"
					style="float: left; margin:0px 20px;width:152px"></SingleUpload>
				<!-- 2022-4-16 转单小包 上传附件，通过用户填写的包裹数量上传文件后台验证数量信息是否正确，没问题返回excel文件数据，再提交信息 -->
				<div style="width: 100px; display: inline-block; float: left;">
					<el-upload v-show="form.skuCount > 0 && form.warehouseType == '6'" class="upload-demo"
						:action="$baseUrl + '/toborder/info/check'" :before-upload="beforeUpload"
						:data={count:form.skuCount} :on-success="handlesuccess" :on-error="handleError"
						:on-progress="handleProgress" multiple :show-file-list="false" :file-list="fileList"
						accept=".xls, .xlsx" :headers="headers" name="file" style="margin-right:15px">
						<el-button type="primary" size="small">{{$t('commonInfo.uploadFileName')}}</el-button>
						<div v-if="!uploadSuccess" slot="tip" class="el-upload__tip">{{$t('commonInfo.fileLimitExcel')}}
						</div>
						<div style="color: #409eff;height: 40px;line-height: 40px;" v-if="uploadSuccess">
							{{$t('tipsInfo.uploadSuccess')}}</div>
					</el-upload>
				</div>
				<div style="float: left;">
					<el-button size="small" v-show="form.skuCount > 0 && form.warehouseType == '6'"
						@click="handleDownloadUrl">{{$t('commonInfo.downloadTemplate')}}</el-button>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleDialogClose" :disabled="DialogLoading">{{$t('commonInfo.dialogBtnCancel')}}
				</el-button>
				<el-button type="primary" :loading="DialogLoading" :disabled="DialogLoading" @click="checkIsLastOrderNo">
					{{DialogLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 导入Excel后错误的对话框 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.dialogPacketInfoTitle')" :visible.sync="dialogErrorVisible"
			width="840px" :close-on-click-modal="false">
			<el-table :data="uploadData">
				<el-table-column property="tplAwbNo" :label="$t('chinaWhB2BOrderPage.dialogPacketInfoPacketNo')"
					align="center"></el-table-column>
				<el-table-column property="tplAwbLinkPdf" :label="$t('chinaWhB2BOrderPage.dialogPacketInfoLink')"
					align="center"></el-table-column>
				<el-table-column property="errorMsg" :label="$t('chinaWhB2BOrderPage.dialogPacketInfoResult')"
					align="center">
					<template slot-scope="scope">
						<span>{{$t('tipsInfo.success')}}</span>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<!-- 查看商品列表 -->
		<el-dialog title="商品信息" :visible.sync="dialogProdcutVisible" width="940px" :close-on-click-modal="false">
			<el-table :data="checkProductList">
				<el-table-column property="productNm" label="商品名称" align="center"></el-table-column>
				<el-table-column property="companyProductCode" label="客户商品编号" align="center"></el-table-column>
				<el-table-column property="registerStatus" label="批次状态" align="center">
					<template slot-scope="scope">
						<span>{{returnStatus(scope.row.registerStatus)}}</span>
					</template>
				</el-table-column>
				</el-table-column>
				<el-table-column property="registerCount" label="登记商品数量" align="center"></el-table-column>
				<el-table-column property="arrivalAmount" label="到货商品数量" align="center"></el-table-column>
				<el-table-column property="exp" label="备注" align="center"></el-table-column>
			</el-table>
		</el-dialog>
		<!-- 添加品名对话框 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.dialogAddKindnameTitle')" :visible.sync="kindNamePopup"
			append-to-body width="940px" :close-on-click-modal="false" @close="closeDialog" v-loading="loading">
			<el-form ref="kindNameForm" :model="kindNameForm" label-width="150px">
				<el-row v-for="(item, index) in addKindNameList" :key="index" :gutter="4">
					<el-col :span="10">
						<el-form-item
							:label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'">
							<el-input v-model="item.productChineseDesc"
								:placeholder="$t('tipsInfo.inputPlaceholder') + ' ' + $t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageZh') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-form-item
							:label="$t('chinaWhB2BOrderPage.dialogAddKindname') + '(' + $t('commonInfo.languageEn') +')'">
							<el-input v-model="item.productEnglishDesc" @keyup.enter.native="addItem()"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('chinaWhB2BOrderPage.dialogAddKindname') + ' ' + '(' + $t('commonInfo.languageEn') +')'" />
						</el-form-item>
					</el-col>
					<el-col :span="2">
						<el-button size="medium" type="text" icon="el-icon-circle-plus" style="margin: 0 5px;"
							@click="addItem()" />
						<el-button size="medium" type="text" icon="el-icon-delete" @click="delItem(index)" />
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button :disabled="addKindNameLoading" @click="closeDialog">{{$t('commonInfo.dialogBtnCancel')}}
				</el-button>
				<el-button :disabled="addKindNameLoading" :loading="addKindNameLoading" type="primary"
					@click="submitNewKindName" style="margin-left:10px;">{{$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 修改FBA信息 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.actionModifyFBA')" :visible.sync="fbaPopup"
			:close-on-click-modal="false" width="880px" :append-to-body="true" @close="closeFBADialog"
			v-loading="fbaLoading">
			<el-form label-width="140px" :model="FBAform" ref="FBAInfoForm" :inline="true">
				<el-divider content-position="left">{{$t('commonInfo.orderInfo')}}</el-divider>
				<el-form-item :label="$t('commonInfo.deliveryCountry')" prop="destCountry">
					<el-input v-model="FBAform.destCountry" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.deliveryWarehouse')" prop="destWhNo">
					<el-input v-model="FBAform.destWhNo" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.deliveryDest')" prop="otherDest">
					<el-input v-model="FBAform.otherDest" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.fbaNo')" prop="fbaNo">
					<el-input v-model="FBAform.fbaNo" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.fbaNoCode')" prop="fbaNoCode">
					<el-input v-model="FBAform.fbaNoCode" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.skuCount')" prop="skuCount">
					<el-input v-model="FBAform.skuCount" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.declaredValue')" prop="declaredValue">
					<el-input v-model="FBAform.declaredValue" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.packageAmount')" prop="packageCount">
					<el-input v-model="FBAform.packageCount" style="width: 250px;" disabled></el-input>
				</el-form-item>
				<el-divider content-position="left">{{$t('chinaWhB2BOrderPage.dialogModifyFBANewFBAInfo')}}</el-divider>
				<el-form-item :label="$t('commonInfo.fbaNo')" prop="afterFbaNo"
					:rules="{required:true,message:$t('tipsInfo.inputPlaceholder') + ' ' + $t('commonInfo.fbaNo'),trigger:'blur'}">
					<el-input v-model="FBAform.afterFbaNo" style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.fbaNoCode')" prop="afterFbaNoCode" :rules="[{required:true,message:$t('tipsInfo.inputPlaceholder') + ' ' + $t('commonInfo.fbaNoCode'),trigger:'blur'},
				{min:8,max:8,message:$t('commonInfo.fbaNoCodeLimit'),trigger:'change'}]">
					<el-input v-model="FBAform.afterFbaNoCode" style="width: 250px;"></el-input>
				</el-form-item>
			</el-form>
			<el-footer style="height: 140px; position: relative;">
				<div>
					<!-- 上传FBA附件 -->
					<SingleUploadPDF v-model="updateFbaFile" :tipText="$t('commonInfo.fbxFileTips')"
						:preFile="updateFbxPreName" @input="getupdateFile"
						style="width: 120px;float: left;margin-left: 20px;">
					</SingleUploadPDF>
				</div>
				<el-button type="primary" :loading="submitFbaLoading" :disabled="submitFbaLoading" @click="submitFBA"
					style="position: absolute;right: 50px; top: 70px;">
					{{submitFbaLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</el-footer>
		</el-dialog>
		<!-- 创建修改订单成功显示面单信息 -->
		<el-dialog :title="$t('commonInfo.orderInfo')" :visible.sync="detailPopup" width="620px" append-to-body
			:close-on-click-modal="false" :before-close="handleCloseDetail">
			<div class="stepthrees">
				<div class="stepthree">
					<div class="three">
						<span>
							{{$t('commonInfo.orderNoColumn') + ':'}}
							<span
								style="font-size: 18px; color: red">{{ detail_orderNo }}</span>{{'，' + $t('commonInfo.total')}}
							<span style="font-size: 18px; color: red">{{orderCaseNum}}</span>
							{{$t('commonInfo.case')}}</span>
						<span>{{$t('commonInfo.deliveryCountry') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destCountry || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryWarehouse') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_destWhNo == ""?$t('commonInfo.customeWarehouse'):detail_destWhNo || "------"}}</span></span>
						<span>{{$t('commonInfo.deliveryDest') + '：'}}<span
								style="font-size: 18px; color: red">{{detail_otherDest || "------"}}</span></span>
						<span>{{$t('commonInfo.declaredValue') + '(USD)：'}}<span
								style="font-size: 18px; color: red">{{detail_declaredValue}}</span></span>
						<span>{{$t('chinaWhB2BOrderPage.szDlvdWarehouseAddress') + ':'}}
							<ul style="list-style: none">
								<li style="color: red; font-size: 18px">{{whAddress}}</li>
								<li>
									{{$t('commonInfo.contactMan') + ':' + whContactMan}}
									<span>({{userName}})</span>
								</li>
								<li>{{$t('commonInfo.contact') + ':' + whContactPhone}}</li>
							</ul>
						</span>
						<el-row v-show="createErrorInfo != ''">
							<span style="font-size: 12px;color: gray;"><i style="margin-right: 5px;"
									class="el-icon-warning"></i>{{createErrorInfo}}</span>
						</el-row>
						<el-row class="table-grid-content">
							<el-col v-show="frontOrafter == '1'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="primary" v-if="PdfLoading" :loading="PdfLoading">
									{{$t('tipsInfo.downloadTips')}}</el-button>
								<el-button v-else type="primary" class="btn-right" @click="getPdf"
									:disabled="PdfLoading">
									{{$t('commonInfo.downloadBoxStickers')}}
								</el-button>
							</el-col>
							<el-col v-show="frontOrafter == '0'" :span="2" class="grid" :gutter="15"
								style="width: 100%; text-align: center">
								<el-button type="info" class="btn-right" :loading="true">
									{{$t('chinaWhB2BOrderPage.orderReviewTips')}}
								</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</el-dialog>

		<!-- 轨迹对话窗 -->
		<el-dialog :title="$t('commonInfo.trackingTitle')" :visible.sync="popups" width="880px" append-to-body
			:close-on-click-modal="false" class="timeline" :before-close="handleDialogClose">
			<!-- 			<div style="text-align:right;font-size:18px;color:red">
				<span>{{this.onRemarks}}</span>
				<span v-show="this.isShow">：</span>
				<span>{{this.warehouseTime}}</span>
			</div> -->
			<div class="dialog_orderNo">
				<label>{{rowOrderNo}}</label>
				<label
					v-show="dlvdMainDeliveryTime != ''">{{$t('commonInfo.appointmentTime') + '：' + dlvdMainDeliveryTime}}</label>
			</div>
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in mainActiveData" :key="index" color="green"
					:timestamp="(activity.initTime)" placement="top" v-show="activity.status==1000?false:true">
					<el-card v-if="activity.status != 'batchInfo'">
						<!-- 轨迹区域 -->
						<h4>{{activity.description}}</h4>
						<!-- location -->
						<p v-show="activity.location==false?false:activity.location==null?false:true">
							{{$t('commonInfo.trackLocation') + ':'}}
							<span style="font-weight:700">{{activity.location}}</span>
						</p>
						<!-- 备注 -->
						<p>
							{{$t('commonInfo.remark') + ':'}}
							<span style="font-weight:700">{{activity.remarks}}</span>
						</p>
					</el-card>
					<!-- 分批信息 -->
					<el-card v-if="activity.status == 'batchInfo'">
						<el-row
							style="background-color: #f9f9f9; margin-bottom: 15px;border-radius: 10px;padding-top: 20px;"
							v-for="(subOrderData,subOrderIndex) in subActiveData" :key="subOrderIndex">
							<div class="dialog_subOrderNo">
								<label>{{$t('commonInfo.batch')+': '+subOrderData[0].esnadTobOrderNo}}</label>
								<div style="display: inline-flex;" v-for="(trackItem,trackIndex) in subOrderData"
									:key="trackIndex">
									<label style="color: #5981c9;font-size: 16px;margin-left: 150px;"
										v-if="trackItem.status == '1000'">{{$t('commonInfo.appointmentTime') + '：' + trackItem.description}}</label>
								</div>
							</div>
							<!-- 子订单轨迹 -->
							<el-timeline>
								<el-timeline-item v-for="(subActivity,subIndex) in subOrderData" :key="subIndex"
									color="#cbac39" :timestamp="(subActivity.initTime)" placement="top"
									v-show="subActivity.status==1000?false:true">
									<p style="font-size:16px">
										<span style="font-weight:700">{{subActivity.remarks}}</span>
									</p>
									<!-- 轨迹区域 -->
									<h4 style="color:#666;font-size:16px">
										[ {{subActivity.location}} ]
										{{subActivity.description}}
									</h4>
					 		</el-timeline-item>
							</el-timeline>
						</el-row>
					</el-card>
				</el-timeline-item>
			</el-timeline>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleDialogClose">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
			</div>
		</el-dialog>

		<!-- 添加修改海运订单 -->
		<el-dialog :title="title" :visible.sync="addOceanPopup" width="800px" append-to-body
			:close-on-click-modal="false" @close="handleCloseSeaOrderDialog" v-loading="orderLoading">
			<el-form class="formList" ref="formList" :model="formList" :inline="true" :rules="rulesList"
				label-width="120px">
				<el-form-item :label="$t('commonInfo.clientOrderNoColumn')" prop="clientAwbNo">
					<el-input v-model="formList.clientAwbNo" clearable
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientOrderNoColumn')"
						@blur="onInputBlur" style="width:240px" />
				</el-form-item>
				<el-form-item :label="$t('commonInfo.packageAmount')" prop="packageAmount">
					<el-input v-model.number="formList.packageAmount" clearable
						:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.packageAmount')"
						style="width:240px" />
				</el-form-item>
				<el-form-item :label="$t('commonInfo.cnConsignorWhHouse')" prop="cnConsignorWh">
					<el-select v-model="formList.cnConsignorWh" clearable filterable default-first-option
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.cnConsignorWhHouse')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in consignorType" :key="item.dictValue" :label="item.dictLabel"
							:value="item.dictValue">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('B2BOrder.deType')" prop="destType">
					<el-select v-model="formList.destType" clearable filterable default-first-option
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('B2BOrder.deType')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in destTypeList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item :label="$t('B2BOrder.deCountry')" prop="destCountry">
					<el-select v-model="formList.destCountry"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('B2BOrder.deCountry')"
						style="margin-left: 0px;width:240px">
						<el-option v-for="item in destCountryType" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<!-- 是否报关退税 -->
				<el-form-item :label="$t('chinaWhB2BOrderPage.customsDeclaration')" prop="customsDeclarationFlag">
					<el-radio style="margin-right: 40px;" v-model="formList.customsDeclarationFlag" :label="true">
						{{$t('chinaWhB2BOrderPage.officialCD')}}</el-radio>
					<el-radio v-model="formList.customsDeclarationFlag" :label="false">
						{{$t('chinaWhB2BOrderPage.unofficialCD')}}</el-radio>
				</el-form-item>
			</el-form>
			<!-- 订单目的地信息 -->
			<el-collapse-transition>
				<div v-show="formList.destCountry != '' && formList.packageAmount != ''">
					<el-divider style="margin: 20px 0;" content-position="left">
						<h3>{{$t('chinaWhB2BOrderPage.seaOrderDestTitle')}}</h3>
					</el-divider>
					<el-row style="margin: 10px 0;">
						<el-button type="primary" icon="el-icon-plus" @click="handleAddSeaDest">
							{{$t('commonInfo.addBtn')}}
						</el-button>
						<el-button v-if="seaOrderDestList.length > 1" type="danger" icon="el-icon-delete"
							@click="handleClearSeaOrderDestInfo(isSa)">
							{{$t('commonInfo.deleteAllBtn')}}
						</el-button>
					</el-row>
					<el-table :data="seaOrderDestList" :loading="seaDestTableLoading" ref="seaDestTable" border
						style="width: 100%">
						<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="80">
							<template slot-scope="{row}">
								<span>{{modifyOcean?row.destType:returnDestType(row.destType)}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('commonInfo.deliveryAddress')" align="center" prop="destType"
							min-width="160">
							<template slot-scope="{row}">
								<!-- FBA FBN 显示仓库名称 OWH显示地址 -->
								<div v-if="row.destType == 'OWH'">
									<span v-if="row.destWh == 'custom'">{{row.destWhAddress}}</span>
									<span v-else>{{row.destWh}}</span>
								</div>
								<div v-else><span>{{row.destWh}}</span></div>
							</template>
						</el-table-column>
						<el-table-column :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" align="center"
							min-width="100">
							<template slot-scope="{row}">
								<span>{{row.packageIndexString}}</span>
							</template>
						</el-table-column>
						<el-table-column :label="$t('commonInfo.operationColumn')" align="center" prop="destType"
							min-width="100">
							<template slot-scope="{row}">
								<el-button type="text" size="small" @click="handleUpdateDestInfo(row)"
									icon="el-icon-edit">{{$t('commonInfo.actionModify')}}</el-button>
								<el-button type="text" size="small" @click="handleDeleteDestInfo(row)"
									icon="el-icon-delete">{{$t('commonInfo.deleteBtn')}}</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-collapse-transition>
			<!-- 解析文件提示 -->
			<div class="skuFileTip">
				<h4><i class="el-icon-warning"></i>上传清单文件解析失败常见原因：</h4>
				<span>1、图片不要嵌入式，不能超出单元格</span>
				<span>2、单箱产品数量、总数量、申报单价、申报总金额 不要为空，若不清楚则填写0</span>
				<span>3、检查是否有空白行以及特殊符号</span>
			</div>
			<div style="margin: 10px 0;height: 60px;">
				<SingleUpload @input="getExcelTwo" :tipText="$t('commonInfo.skuFileTips')" :preFile="fileTXTName"
					style="float: left;margin-left:22px;width:152px"></SingleUpload>
				<el-button size="small" style="float: left;margin-left:5px;" icon="el-icon-download"
					@click="handleDownloadTemplate">{{$t('commonInfo.downloadTemplate')}}</el-button>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCloseSeaOrderDialog" :disabled="ocanLoading">
					{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" @click="submitForm" :disabled="ocanLoading" :loading="ocanLoading">
					{{ocanLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 添加海运目的地信息窗口 -->
		<el-dialog :title="seaDestTitle" :visible.sync="addSeaOrderDestPopup" width="600px" append-to-body
			:close-on-click-modal="false" @close="handleCloseSeaOrderDestDialog">
			<el-form ref="seaDestForm" :model="seaDestForm" :inline="true" :rules="seaDestRules" label-width="120px">
				<el-form-item :label="$t('chinaWhB2BOrderPage.destType')" prop="destType">
					<el-select v-model="seaDestForm.destType"
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('chinaWhB2BOrderPage.destType')"
						style="width:400px">
						<el-option v-for="item in seaDestTypeList" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="seaDestForm.destType != ''" :label="$t('commonInfo.deliveryWarehouse')"
					prop="destWh">
					<el-select v-model="seaDestForm.destWh" filterable
						:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.deliveryWarehouse')"
						style="width: 400px;">
						<el-option v-for="item in selectWhList" :key="item.dictCode" :label="item.dictLabel"
							:value="item.dictValue"></el-option>
					</el-select>
				</el-form-item>
				<el-collapse-transition>
					<div v-show="showSeaDestInfo">
						<el-form-item :label="$t('commonInfo.deliveryDest')" prop="destWhAddress">
							<el-input style="width: 400px;" v-model="seaDestForm.destWhAddress"
								:disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.deliveryDest')">
							</el-input>
						</el-form-item>
						<el-form-item v-if="isSa == 1" :label="$t('commonInfo.city')"
							prop="destCity">
							<el-select v-model="seaDestForm.destCity" :disabled="editSeaOrderCity"
								:placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.city')"
								style="width: 400px;">
								<el-option v-for="item in saCityList" :key="item.dictCode" :label="item.dictLabel"
									:value="item.dictLabel"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('commonInfo.fbaNo')" prop="fbaNo">
							<el-input v-model="seaDestForm.fbaNo" :placeholder="$t('commonInfo.FBATips')"
								style="width: 400px"></el-input>
						</el-form-item>

						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('commonInfo.fbaNoCode')"
							prop="fbaTrackingNo">
							<el-input v-model="seaDestForm.fbaTrackingNo" style="width: 400px"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.fbaNoCode')"></el-input>
						</el-form-item>
						<!-- 预约送仓时间 -->
						<el-form-item v-if="seaDestForm.destType == '2'" :label="$t('commonInfo.deliveryDate')"
							prop="reservationTime">
							<el-date-picker clearable style="width: 400px;" v-model="seaDestForm.reservationTime"
								:picker-options="deliveryPickerOptions" type="date" value-format="yyyy-MM-dd"
								:placeholder="$t('chinaWhB2BOrderPage.deliveryDateTips')"></el-date-picker>
						</el-form-item>
						<!-- 预约信息 注意：该字段与上传PDF文件绑定，填写或者上传某一个，则另一个必填-->
						<el-form-item v-if="seaDestForm.destType == '2'" :label="$t('commonInfo.asnNo')" prop="fbnNo">
							<el-input v-model="seaDestForm.fbnNo" style="width: 400px"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.asnNo')"></el-input>
						</el-form-item>
						<!-- 选择自定义仓库时 需要必填联系人和联系电话 -->
						<el-form-item :label="$t('commonInfo.contactMan')" v-if="seaDestForm.destType == '5'"
							prop="consignee">
							<el-input v-model="seaDestForm.consignee" style="width: 400px" :disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contactMan')"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.contact')" v-if="seaDestForm.destType == '5'"
							prop="contactTel">
							<el-input v-model="seaDestForm.contactTel" style="width: 400px" :disabled="editSeaOtherDest"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.contact')"></el-input>
						</el-form-item>
						<!-- 箱序号 -->
						<el-form-item :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" prop="packageIndexString">
							<el-input v-model="seaDestForm.packageIndexString" style="width: 400px" type="textarea"
								:placeholder="$t('chinaWhB2BOrderPage.inputCartonIndexTips')"></el-input>
						</el-form-item>
						<!-- 派送箱总PCS -FBA类型 -->
						<el-form-item v-if="seaDestForm.destType == '1'" :label="$t('chinaWhB2BOrderPage.cartonTotalPcs')" prop="pcsNum">
							<el-input v-model="seaDestForm.pcsNum" style="width: 400px" type="text"
								:placeholder="$t('chinaWhB2BOrderPage.cartonTotalPcsTips')"></el-input>
						</el-form-item>
						<el-form-item :label="$t('commonInfo.remark')" prop="remarks">
							<el-input v-model="seaDestForm.remarks" style="width: 400px" type="textarea"
								:placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.remark')"></el-input>
						</el-form-item>
					</div>
				</el-collapse-transition>
			</el-form>
			<div v-show="showSeaDestInfo && seaDestForm.destType != '5'" style="margin: 10px 0;height: 60px;">
				<SingleUploadPDF v-model="seaReservationFile" :tipText="$t('commonInfo.fbxFileTips')"
					:preFile="seaFbxPreName" @input="getSeaFBXInfo" style="width: 120px;float: left;margin-left: 20px;">
				</SingleUploadPDF>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCloseSeaOrderDestDialog" :disabled="seaDestLoading">
					{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button type="primary" :disabled="seaDestLoading" :loading="seaDestLoading"
					@click="submitSeaOrderDestInfo">
					{{seaDestLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}
				</el-button>
			</div>
		</el-dialog>
		<!-- 海运订单目的地信息详情 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.seaOrderDestTitle')" :visible.sync="checkDestInfoPopup" width="800px"
			append-to-body :close-on-click-modal="false" @close="handleCloseCheckDestDialog">
			<el-row class="seaOrderDestDetail">
				<el-col :span="4"><label>{{$t('commonInfo.orderNoColumn') + ':'}}</label></el-col>
				<el-col :span="8"><span>{{checkDestInfoDetail.awbNo}}</span></el-col>
				<el-col :span="4"><label>{{$t('commonInfo.packageAmount') + ':'}}</label></el-col>
				<el-col :span="8"><span>{{checkDestInfoDetail.packageAmount}}</span></el-col>
				<el-col :span="4"><label>{{$t('commonInfo.cnConsignorWhHouse') + ':'}}</label></el-col>
				<el-col :span="8"><span>{{checkDestInfoDetail.cnConsignorWh}}</span></el-col>
				<el-col :span="4"><label>{{$t('B2BOrder.deCountry') + ':'}}</label></el-col>
				<el-col :span="8"><span>{{checkDestInfoDetail.destCountry}}</span></el-col>
			</el-row>
			<el-divider style="margin: 20px 0;" content-position="left">
				<h3>{{$t('chinaWhB2BOrderPage.seaOrderDestTitle')}}</h3>
			</el-divider>
			<!-- 到港后不能修改目的地信息 -->
			<el-row v-if="checkDestInfoDetail.trackingStatusValue < 320" style="margin: 10px 0;">
				<el-button type="primary" icon="el-icon-plus" @click="handleAddSeaDetailDest">
					{{$t('commonInfo.addBtn')}}
				</el-button>
				<el-button v-if="seaOrderDestList.length > 1" type="danger" icon="el-icon-delete"
					@click="handleClearSeaOrderDestInfo(isSa)">
					{{$t('commonInfo.deleteAllBtn')}}
				</el-button>
			</el-row>
			<el-table :data="seaOrderDestList" ref="seaDetailDestTable" border style="width: 100%">
				<el-table-column :label="$t('B2BOrder.deType')" align="center" prop="destType" min-width="80">
					<template slot-scope="{row}">
						<span>{{row.destType}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.deliveryAddress')" align="center" prop="destType"
					min-width="160">
					<template slot-scope="{row}">
						<!-- FBA FBN 显示仓库名称 OWH显示地址 -->
						<div v-if="row.destType == 'OWH'">
							<span v-if="row.destWh == 'custom'">{{row.destWhAddress}}</span>
							<span v-else>{{row.destWh}}</span>
						</div>
						<div v-else><span>{{row.destWh}}</span></div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('chinaWhB2BOrderPage.seaCartonIndex')" align="center" min-width="100">
					<template slot-scope="{row}">
						<span>{{row.packageIndexString}}</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('commonInfo.operationColumn')" align="center" prop="destType"
					min-width="100">
					<template slot-scope="{row}">
						<el-button v-if="checkDestInfoDetail.trackingStatusValue < 320" type="text" size="small"
							@click="handleUpdateDestInfo(row)" icon="el-icon-edit">{{$t('commonInfo.actionModify')}}
						</el-button>
						<el-button v-if="checkDestInfoDetail.trackingStatusValue < 320" type="text" size="small"
							@click="handleDeleteDestInfo(row)" icon="el-icon-delete">{{$t('commonInfo.deleteBtn')}}
						</el-button>
						<el-button v-if="checkDestInfoDetail.trackingStatusValue >= 320" type="text" size="small"
							@click="handleCheckDestInfo(row)" icon="el-icon-view">{{$t('commonInfo.actionDetail')}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCloseCheckDestDialog">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
			</div>
		</el-dialog>
		<!-- 订单目的地详情 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.destInfo')" :visible.sync="destDetailPopup"
			:close-on-click-modal="false" width="600px" :append-to-body="true">
			<el-descriptions style="margin-bottom: 20px;" :column="1" border :labelStyle="{'width':'120px'}">
				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.destType')">{{destDetailForm.destType}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.deliveryWarehouse')">
					{{destDetailForm.destWh == 'custom'?"自定义海外仓":destDetailForm.destWh}}</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.deliveryDest')">{{destDetailForm.destWhAddress}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.city')">{{destDetailForm.destCity}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA'" :label="$t('commonInfo.fbaNo')">
					{{destDetailForm.fbaNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA'" :label="$t('commonInfo.fbaNoCode')">
					{{destDetailForm.fbaTrackingNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBN'" :label="$t('commonInfo.deliveryDate')">
					{{destDetailForm.reservationTime}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBN'" :label="$t('commonInfo.asnNo')">
					{{destDetailForm.fbnNo}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'OWH'" :label="$t('commonInfo.contactMan')">
					{{destDetailForm.consignee}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'OWH'" :label="$t('commonInfo.contact')">
					{{destDetailForm.contactTel}}</el-descriptions-item>

				<el-descriptions-item :label="$t('chinaWhB2BOrderPage.seaCartonIndex')">
					{{destDetailForm.packageIndexString}}</el-descriptions-item>
				<el-descriptions-item v-if="destDetailForm.destType == 'FBA' || destDetailForm.destType == 'FBN'"
					:label="$t('commonInfo.actionDownloadPDF')">
					<el-link type="primary" :href="destDetailForm.reservationFile" target="_blank">
						{{$t('commonInfo.actionDownload')}}</el-link>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.remark')">{{destDetailForm.remarks}}</el-descriptions-item>
			</el-descriptions>
		</el-dialog>

		<!-- 海运轨迹对话窗 -->
		<el-dialog :title="$t('commonInfo.orderTrack')" :visible.sync="popup" :close-on-click-modal="false"
			width="860px" :append-to-body="true" class="timeline" :before-close="handleConfirm">
			<div class="dialog_orderNo">
				<label>{{ awbNo }}</label>
			</div>
			<div class="dialog_extraInfo">
				<div class="trackingOpt leave" v-if="leaveTracking != null">
					<i class="el-icon-ship"></i>
					<span>{{leaveTracking.description}}</span>
					<span>{{leaveTracking.enDescription}}</span>
				</div>
				<div class="trackingOpt arrive" v-if="arriveTracking != null">
					<i class="el-icon-s-home"></i>
					<span>{{arriveTracking.description}}</span>
					<span>{{arriveTracking.enDescription}}</span>
				</div>
			</div>
			<el-timeline>
				<el-timeline-item v-for="(activity, index) in activiData" :key="index" color="green"
					:timestamp="(activity.trackingTime)" placement="top"
					v-show="activity.status == 1000 ? false : true">
					<el-card>
						<!-- 备注 -->
						<p style="font-size:16px;line-height: 30px;">
							<span style="font-weight:700">Remarks：{{activity.description}}</span>
						</p>
						<!-- 轨迹区域 -->
						<h4 style="color:#666;font-size:16px;line-height: 30px;">
							[ {{activity.location}} ]
							{{activity.enDescription}}
						</h4>
					</el-card>
				</el-timeline-item>
			</el-timeline>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleConfirm">{{$t('commonInfo.dialogBtnConfirm')}}</el-button>
			</div>
		</el-dialog>

		<!-- 订单列表 -->
		<el-dialog :title="$t('commonInfo.orderDeList')" :visible.sync="productPopup" :close-on-click-modal="false"
			width="1000px" :append-to-body="true">
			<el-descriptions style="margin-bottom: 20px;" :column="2" border :labelStyle="{'width':'180px'}"
				:title="$t('commonInfo.orderDetails')">
				<el-descriptions-item :label="$t('commonInfo.orderNoColumn')">{{orderDetailsList.awbNo}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.clientOrderNoColumn')">{{orderDetailsList.clientAwbNo}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.packageAmount')">{{orderDetailsList.packageAmount}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.weightKG')">{{orderDetailsList.billingWeight}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.chargeVolumeCbm')">{{orderDetailsList.billingVolume}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('clientInfoPage.clientShortName')">{{orderDetailsList.clientShortName}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.goodCnDesc')" span="2">{{orderDetailsList.productCnDesc}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.goodEnDesc')" span="2">{{orderDetailsList.productEnDesc}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.cnConsignorWh')">{{orderDetailsList.cnConsignorWh}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.deCountry')">{{orderDetailsList.destCountry}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.deType')">{{orderDetailsList.destType}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.goodType')">{{orderDetailsList.productType}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.statusColumn')">{{orderDetailsList.trackingStatus}}
				</el-descriptions-item>
				<el-descriptions-item v-if="orderDetailsList.awbUrl != '' && orderDetailsList.awbUrl != null"
					:label="$t('commonInfo.faceNoodles')">
					<el-link type="primary" :href="orderDetailsList.awbUrl" target="_blank">
						{{$t('commonInfo.actionDownload')}}</el-link>
				</el-descriptions-item>
				<el-descriptions-item :label="$t('commonInfo.createTimeColumn')">{{orderDetailsList.initTime}}
				</el-descriptions-item>
				<el-descriptions-item :label="$t('B2BOrder.examineStatus')">{{orderDetailsList.reviewStatus}}
				</el-descriptions-item>
				<el-descriptions-item label="报关"><span>{{orderDetailsList.customsDeclarationFlag==true?$t('chinaWhB2BOrderPage.officialCD'):$t('chinaWhB2BOrderPage.unofficialCD')}}</span></el-descriptions-item>
				<el-descriptions-item v-if="orderDetailsList.skuListLink != '' && orderDetailsList.skuListLink != null"
					span="2" :label="$t('commonInfo.skuFile')">
					<el-link type="primary" :href="orderDetailsList.skuListLink" target="_blank">
						{{$t('commonInfo.actionDownload')}}</el-link>
				</el-descriptions-item>
			</el-descriptions>
			<el-descriptions style="margin-bottom: 20px;" :column="2" border :labelStyle="{'width':'150px'}"
				:title="$t('B2BOrder.cartonInfo')">
			</el-descriptions>
			<el-table style="margin:5px 10px;width: calc(100% - 10px);" :data="boxInformationList" border>
				<!-- <el-table-column :label="$t('commonInfo.orderNoColumn')" align="center" prop="awbNo" width="140px"/> -->
				<el-table-column :label="$t('commonInfo.packageNo')" align="center" prop="packageAwbNo" width="180px" />
				<el-table-column :label="$t('commonInfo.index')" align="center" prop="packageIndex" />
				<el-table-column :label="$t('commonInfo.length') + '(CM)'" align="center" prop="length" />
				<el-table-column :label="$t('commonInfo.breadth') + '(CM)'" align="center" prop="breadth" />
				<el-table-column :label="$t('commonInfo.height') + '(CM)'" align="center" prop="height" />
				<el-table-column :label="$t('commonInfo.grossWeight') +'(KG)'" align="center" prop="grossWeight" />
				<el-table-column :label="$t('commonInfo.chargeVolume') +'(CBM)'" align="center" prop="volume" />
				<el-table-column prop="picUrl" min-width="100" :label="$t('commonInfo.photo')" align="center">
					<template slot-scope="{row}">
						<div v-if="row.picUrl != null && row.picUrl != ''">
							<el-button type="text" @click="handleViewCartonImg(row.picUrl)" icon="el-icon-view">
								{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
						</div>
						<span v-else></span>
					</template>
				</el-table-column>
			</el-table>
			<div class="pageBottom">
				<el-pagination @size-change="siChange" @current-change="curChange" :current-page="params.page"
					:page-sizes="[10, 20, 30, 40]" :page-size="params.limit"
					layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
			</div>
		</el-dialog>
		<!--  批量下单窗口  -->
		<el-dialog
		  title="批量下单"
		  :visible.sync="batchCreateOrderPopup"
			:close-on-click-modal="false"
			:before-close="handleBatchCreateOrderDialogClose"
		  width="1000px">
			<div style="margin: 10px 0; height: 60px;">
				<el-upload
				  class="upload-demo"
				  :action="$baseUrl + '/toborder/batchCreateCheck'"
				  :before-upload="beforeUpload"
				  :on-success="handleSuccessMutipleOrder"
				  :on-error="handleError"
					:show-file-list="false"
				  :on-progress="handleProgress"
				  accept=".xls, .xlsx"
				  :headers="headers"
				  name="file"
				  style="margin-right:15px; width: 140px; display: inline-block;float: left;"
				>
				  <el-button type="primary">{{$t('commonInfo.uploadExcel')}}</el-button>
				</el-upload>
				<el-button style="float: left;"
				  @click="handleDownloadModel"
				>{{$t('menuItem.templateDownloadItem')}}</el-button>
			</div>
			<el-table :data="multipleUploadData" border>
				<el-table-column property="destCountry" label="国家" align="center"></el-table-column>
				<el-table-column property="destWhNo" label="仓库" align="center">
				</el-table-column>
			  <el-table-column property="fbaNo" label="FBA号/ASN号" align="center"></el-table-column>
			  <el-table-column property="fbaNoCode" label="FBA追踪编码" align="center"></el-table-column>
			  <el-table-column property="lgPackageCount" label="箱数" align="center"></el-table-column>
			  <el-table-column property="productAmount" label="商品总件数" align="center"></el-table-column>
			  <el-table-column property="declaredValue" label="申报金额(USD)" align="center"></el-table-column>
			  <el-table-column property="errorMessage" label="信息" align="center">
			    <template slot-scope="{row}">
			      <span>{{row.errorMessage==null?'成功':row.errorMessage}}</span>
			    </template>
			  </el-table-column>
			</el-table>
		  <span slot="footer" class="dialog-footer">
		    <el-button :disabled="multipleCreateOrderLoading" @click="handleBatchCreateOrderDialogClose">取消</el-button>
		    <el-button type="primary" :disabled="multipleCreateOrderLoading && !isSuccessData" :loading="multipleCreateOrderLoading" @click="submitMultipleCreateOrder">{{multipleCreateOrderLoading?'提交中':'提 交'}}</el-button>
		  </span>
		</el-dialog>
		<!-- 海运订单审核不通过原因 -->
		<el-dialog :title="$t('chinaWhB2BOrderPage.seaOrderFailedReason')" :visible.sync="checkSeaOrderFailedPopup" :close-on-click-modal="false" width="500px"
		  :before-close="handleCloseSeaOrderFailedReasonDialog"
		  :append-to-body="true">
		  <el-form :model="seaOrderRow" label-width="100px">
		    <el-form-item :label="$t('commonInfo.orderNoColumn')" >
		      <el-tag>{{seaOrderRow.awbNo}}</el-tag>
		    </el-form-item>
				<el-form-item v-if="seaOrderRow.reviewUrl != null && seaOrderRow.reviewUrl != ''" :label="$t('commonInfo.skuFile')" >
				  <el-link type="primary" :href="seaOrderRow.reviewUrl">{{$t('commonInfo.actionDownload')}}</el-link>
				</el-form-item>
		    <el-form-item :label="$t('commonInfo.reason')" prop="reviewRemarks">
		      <span class="parsingFilesFailedTips" v-html="returnParsingFilesFailedReason()"></span>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="handleCloseSeaOrderFailedReasonDialog">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
		    <el-button type="primary" @click="handleTurnToModifyOrder">{{$t('commonInfo.modifyOrder')}}</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import SingleUploadPDF from "@/components/upload/singleUploadPDF";
	import SingleUpload from "@/components/upload/singleUpload";
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
	import {
		formatDate
	} from "@/utils/index";
	import {
		validNumberToFix2,
		validPositiveInteger
	} from '@/utils/validate' //验证数字
	//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
	//例如：import 《组件名称》 from '《组件路径》';
	export default {
		data() {
			//这里存放数据
			return {
				//海运
				activeName: 'first',
				awbNo: '',
				orderNumber: '',
				clientAwbNo: '',
				seaQueryParams: {
					awbNoList: [],
					clientAwbNoList: [],
					page: 1,
					limit: 10,
				},
				params: {
					page: 1,
					limit: 10,
				},
				seaOrderList: [], //列表data
				orderDetailsList: {}, //订单详情列表
				boxInformationList: [], //箱信息列表
				customerOrderNo: '',
				loadingList: false,
				addOceanPopup: false,
				orderLoading: false,
				ocanLoading: false,
				title: '',
				modifyOcean: false, //是否修改
				awbNumber: '',
				productPopup: false,
				consignorType: [],
				destTypeList: [{
						value: 'FBA',
						label: 'FBA',
					},
					{
						value: 'FBN',
						label: 'FBN',
					},
					{
						value: '其他海外仓/私人地址',
						label: '其他海外仓/私人地址',
					},
					{
						value: 'SKU上架',
						label: 'SKU上架',
					},
					{
						value: '整箱上架',
						label: '整箱上架',
					}
				],
				destCountryType: [{
						value: 1,
						label: 'Saudi Arabia(沙特)',
					},
					{
						value: 2,
						label: 'United Arab Emirates(阿联酋)',
					}
				],
				formList: { //增加海运信息
					clientAwbNo: '',
					packageAmount: '',
					cnConsignorWh: '',
					destType: '',
					destCountry: '',
					skuListLink: '',
					customsDeclarationFlag:false,//是否报关
				},
				// 海运订单目的地信息
				seaOrderDestList: [],
				seaDestTableLoading: false,

				// 创建海运订单目的地信息
				seaDestTitle: '',
				editSeaDest: false,
				addSeaOrderDestPopup: false,

				seaDestForm: {
					id: '', //ID
					orderId: '', //订单ID
					index: '', //标识
					destType: '', //FBA NOON OWH
					fbaNo: '', //FBA号
					fbaTrackingNo: '', //FBA追踪编码
					fbnNo: '', //ASN号
					reservationNumber: '', //预约号
					reservationFile: '', //入仓文件
					reservationTime: '', // 预约送仓文件
					destCountry: '', // 目的国家
					destCity: '', //目的城市
					destWh: '', //目的仓库
					destWhAddress: '', //目的送仓地址
					consignee: '', //联系人
					contactTel: '', //联系人电话
					remarks: '', //备注
					packageIndexString: '', //1-5,7,9-10,12
					pcsNum:'',// 当前目的地箱子总PCS数
					cartonIndexStr: '',
					seaOrderDestPackageInfoDto: { // 绑定箱信息
						orderType: '', //订单类型
						// 	packageId:'',//ID
						// 	packageAwbNo:'',//系统箱号
						// 	clientPackageAwbNo:'',//客户箱号
						packageIndex: '', //箱编号 创建订单前没有箱号需要用到
						// 	remarks:'',//备注
					},
				},
				seaDestLoading: false,
				seaReservationFile: '', //预约文件
				seaFbxPreName: [], // 上传文件预览
				seaDestTypeList: [{
						value: '1',
						label: 'FBA',
					},
					{
						value: '2',
						label: 'FBN',
					},
					{
						value: '5',
						label: 'OWH',
					}
				],
				showSeaDestInfo: false,
				editSeaOtherDest: false,
				editSeaOrderCity: false,

				// 查看目的地信息窗口
				checkDestInfoPopup: false,
				checkDestInfoDetail: {
					id: '',
					awbNo: '',
					destCountry: '',
					packageAmount: '',
					cnConsignorWh: '',
					trackingStatusValue: 0, // 订单状态
				},
				// 查看目的地详情窗口
				destDetailPopup: false,
				destDetailForm: {},


				//订单轨迹
				popup: false,
				activiData: [],
				// 轨迹显示修改
				leaveTracking: null,
				arriveTracking: null,
				detailedUrl: '', //海运订单模板

				//空运
				queryParams: {
					page: 1,
					limit: 10,
					orderNo: '',
					start: '',
					end: '',
				},
				orderTime: null,
				tobOrderNo: '',
				tableData: [],
				expandRowArray: [], // 默认展开id数组
				loading: false,
				PdfLoading: false,
				createErrorInfo: '',
				total: 0,
				isShow: false,
				popups: false,
				trackingList: [],
				userName: '',
				whAddress: '东莞市塘厦镇桥蛟中路1号好运童园科技园8栋--飞坦数字物流', //深圳仓库地址
				whContactMan: '', //仓库联系人
				whContactPhone: '', //仓库联系电话
				activities: [],
				mainActiveData: [], //主订单轨迹
				subActiveData: [], //子订单轨迹
				warehouseTime: "",
				onRemarks: "",
				frontOrafter: '1',
				arr: [], // 默认展开的行

				//新建订单
				DialogLoading: false,
				orderTitle: '',
				orderPopup: false,
				form: {
					destCountry: '', //国家
					destCity: '', //城市
					destCountryId: 2,
					warehouseType: '',
					destWhNo: '', //仓库
					otherDest: '', //地址
					fbaNo: '', //FBA号
					fbaNoCode: '', //FBA跟踪编码
					asnNo: '', //noon流程的预约号
					deliveryDate: '', //预约送仓时间
					orderNo: '', //订单编号
					contacts: '', //自定义海外仓 联系人
					contactsTel: '', //自定义海外仓 联系电话
					declaredValue: 0, //申报金额
					skuCount: 0, //SKU总数
					deliveryType: '', //派送方式，1送货上门，2仓库自提
					execllist: [], //转单小包信息
					customsDeclarationFlag: false, //是否报关退税 默认false
					clientOrderNo: '', //客户订单号，必填
				},
				// 空运清单模板
				airTemplate: {
					fba: '',
					noon: '',
					owh: '',
				},
				countryList: [{ //国家选项信息
					name: "Saudi Arabia(沙特)",
					value: "SA"
				}, {
					name: "United Arab Emirates(阿联酋)",
					value: "AE"
				}],
				// 1、FBA，2、NOON，3、Fleetan海外仓（中转FBN/FBA），4、Fullfillment，5其他海外仓/私人地址 6、转单小包
				warehouseTypeList: [],
				isSa: '', //是否选择沙特 1沙特 2阿联酋
				tipInfo: '', //提示信息
				selectWhList: [],
				isEdit: false,
				isHandleSubmit: false, //是否点击提交

				// 预约送仓时间限制选择范围
				deliveryPickerOptions: { // 限制送仓时间的选择范围
					disabledDate(time) {
						let nowDate = Date.now();
						nowDate = nowDate - 24 * 60 * 60 * 1000; //可选当天
						let nowDay = new Date(time).getDay(); //周几
						// 超过当前时间的日期不可选
						return nowDate > time.getTime() ||
							nowDay == 5
					},
				},

				//需要分层显示
				showOne: false, //显示第一层
				showType: false, // 显示派送方式
				showContactInfo: true, //显示联系人和联系电话信息
				showTwo: false, //显示第二层
				showThree: false, //显示第三层
				formData: [], //第二次提交参数
				caseNum: 1, //箱数，可以自动生成箱号
				showCaseDetail: '1',
				fileList: [],
				headers: {},
				imgList: [], //预览POD
				showImg: false,
				uploadData: [], //上传报错返回的数据
				uploadSuccess: false,
				dialogErrorVisible: false, //显示上传报错数据窗口
				uploadloading: false,
				prefixAwbNo: '', //客户五位标识码
				clientId:'',//客户ID

				//箱号生成
				caseLoading: false,
				isSpecial: false, //该客户是否自定义填写箱号信息
				clientEnName: '', //客户的英文简称
				isCaseSpecial: false, //是否显示客户自定义填写箱号
				isCaseNormal: true, //是否显示正常的箱号信息
				orderCaseNum: 0, //订单箱数
				caseData: [],
				caseDataCustom: [], //显示自定义海外仓的箱号数据
				timeout: null, //防抖定时器

				// 查看商品列表
				checkProductList: [],
				dialogProdcutVisible: false,

				filename: '', //SKU文件名
				fileTXTName: [], // 已上传文件集合，通过接口获取文件名和地址url，回传给子组件upload显示该订单已上传的文件列表
				skuListLink: '', //上传SKU文件路径
				//品名
				kindName: [],
				kindNamePopup: false,
				kindNameList: [{ //常用品名
						id: 'common1',
						value: '耳机',
						label: '耳机',
						productChineseDesc: '耳机',
						productEnglishDesc: 'earphone'
					}, {
						id: 'common2',
						value: '手机壳',
						label: '手机壳',
						productChineseDesc: '手机壳',
						productEnglishDesc: 'phone shell'
					}, {
						id: 'common3',
						value: '平板壳',
						label: '平板壳',
						productChineseDesc: '平板壳',
						productEnglishDesc: 'tablet shell'
					}, {
						id: 'common4',
						value: '墙纸',
						label: '墙纸',
						productChineseDesc: '墙纸',
						productEnglishDesc: 'wall paper'
					}, {
						id: 'common5',
						value: '玩具',
						label: '玩具',
						productChineseDesc: '玩具',
						productEnglishDesc: 'toy'
					}, {
						id: 'common6',
						value: '理发器',
						label: '理发器',
						productChineseDesc: '理发器',
						productEnglishDesc: 'haircut machine'
					}, {
						id: 'common7',
						value: '衣服',
						label: '衣服',
						productChineseDesc: '衣服',
						productEnglishDesc: 'clothes'
					}, {
						id: 'common8',
						value: '鼠标套装',
						label: '鼠标套装',
						productChineseDesc: '鼠标套装',
						productEnglishDesc: 'Mouse coordinates'
					},
					{
						id: 'common9',
						value: '咖啡压粉锤',
						label: '咖啡压粉锤',
						productChineseDesc: '咖啡压粉锤',
						productEnglishDesc: 'Coffee Tampers'
					},
					{
						id: 'common10',
						value: '收纳包',
						label: '收纳包',
						productChineseDesc: '收纳包',
						productEnglishDesc: 'Storage bag'
					},
				], //总的品名集合
				addKindNameList: [], //新增品名集合
				addKindNameLoading: false,
				kindNameForm: {},

				//修改fba
				fbaPopup: false,
				fbaOrderNo: '',
				updateFbxLink: '',
				updateFbxPreName: [],
				updateFbaFile: '',
				updateFileName: [],
				FBAform: {
					afterFbaNo: '',
					afterFbaNoCode: '',
				},
				fbxFlienName: '',
				fbxPreName: [],
				fbxLink: '',
				pdfFlienName: '', //上传PDF文件名称
				pdfLink: '', //上传PDF路径
				pdfPreName: [], //pdf上传的预览文件名集合
				sortingFilename: "", //分拣清单附件名称
				sorttingFileTXTName: [], //已上传清单附件名称集合
				sortingSkuLink: "", //分拣清单附件链接
				isSorting: false, //是否需要分拣清单 前提条件为noon订单
				fbaLoading: false,
				submitFbaLoading: false,
				//订单详情窗口
				detailPopup: false,
				detail_orderNo: '',
				detail_destCountry: '',
				detail_destWhNo: '',
				detail_otherDest: '',
				detail_declaredValue: '',

				rowOrderNo: '',
				dlvdMainDeliveryTime: '',
				//是否可以编辑信息
				editContactInfo: false,
				editOtherDest: false,
				editCity: false,
				// 自提信息
				SelfDestWhNo: '',
				SelfOtherDest: '',
				// 转单小包类型模板
				modelUrl: '',

				// 2022-5-17 zpy 查看箱信息通过按钮显示弹窗
				packageDialogPopup: false,
				checkPackageOrderNo: '',
				packageData: [],
				packageListLoading: false,

				// B2B空运OWH增加城市选项
				saCityList: [],
				
				// 空运批量下单
				batchCreateOrderPopup:false,
				multipleCreateOrderLoading:false,
				multipleUploadData:[],
				isSuccessData:false,
				batchCreateOrderModulUrl:'',
			  
				// 海运订单审核不通过
				checkSeaOrderFailedPopup:false,
				seaOrderRow:{},

			};
		},
		//监听属性 类似于data概念
		computed: {
			rules() { // 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
				//自定义验证规则 只能填写数字或者英文字母
				const validateCode = (rule, value, callback) => {
					let reg = /[^\w\.\/]/ig
					if (reg.test(value)) {
						callback(new Error(this.$t('commonInfo.numberAndLetterLimit')))
				  } else {
						callback()
					}
				};
				return {
					destCountry: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.country'),
						trigger: 'blur'
					}],
					warehouseType: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryType'),
						trigger: 'blur'
					}],
					destWhNo: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryWarehouse'),
						trigger: 'blur'
					}],
					otherDest: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDest'),
						trigger: 'blur'
					}],
					fbaNo: [{
							required: true,
							message: this.$t('commonInfo.FBAExample'),
							trigger: 'blur'
						},
						{
							min: 12,
							max: 12,
							message: this.$t('commonInfo.fbaNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					fbaNoCode: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.fbaNoCode'),
							trigger: 'blur'
						},
						{
							min: 8,
							max: 8,
							message: this.$t('commonInfo.fbaNoCodeLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
						 trigger: 'blur',
						}
					],
					asnNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.asnNo'),
						trigger: 'blur'
					}, {
						validator: validateCode,
						trigger: 'blur',
					}],
					deliveryDate: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.deliveryDateTips'),
						trigger: 'blur'
					}],
					contacts: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contactMan'),
						trigger: 'blur'
					}],
					contactsTel: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contact'),
						trigger: 'blur'
					}],
					skuCount: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.skuCount'),
						trigger: 'blur'
					}, {
						validator: validPositiveInteger,
						trigger: 'blur'
					}],
					declaredValue: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.declaredValue'),
						trigger: 'blur'
					}, {
						validator: validNumberToFix2,
						trigger: 'blur'
					}],
					customsDeclarationFlag: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.customsDeclaration'),
						trigger: 'blur'
					}],
					destCity: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.ctiy'),
						trigger: 'blur'
					}],
					clientOrderNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.clientOrderNo'),
						trigger: 'blur'
					}]
				}
			},
			rulesList() {
				//自定义验证规则 只能填写数字或者英文字母、-、_
				const validateCode = (rule, value, callback) => {
					let reg = /^[a-zA-Z0-9_-]+$/
					if (reg.test(value)) {
						callback()
					} else {
						callback(new Error(this.$t('commonInfo.numberLimit')))
					}
				};
				return {
					clientAwbNo: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.clientOrderNoColumn'),
							trigger: 'blur'
						},
						{
							max: 20,
							message: this.$t('commonInfo.clientAwbNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					packageAmount: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.clientOrderNoColumn'),
						trigger: 'blur'
					}],
					cnConsignorWh: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.cnConsignorWhHouse'),
						trigger: 'blur'
					}],
					// destType: [{
					// 	required: true,
					// 	message: this.$t('tipsInfo.selectPlaceholder') + this.$t('B2BOrder.deType'),
					// 	trigger: 'blur'
					// }],
					destCountry: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('B2BOrder.deCountry'),
						trigger: 'blur'
					}],
					customsDeclarationFlag: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t(
							'chinaWhB2BOrderPage.customsDeclaration'),
						trigger: 'blur'
					}],
				}
			},
			seaDestRules() {
				//自定义验证规则 只能填写数字或者英文字母
				const validateCode = (rule, value, callback) => {
					let reg = /[^\w\.\/]/ig
					if (reg.test(value)) {
						callback(new Error(this.$t('commonInfo.numberAndLetterLimit')))
					} else {
						callback()
					}
				};
				// 只能填写数字
				const validateNum = (rule, value, callback) => {
					let reg = /^\+?[1-9][0-9]*$/
					if (!reg.test(value)) {
						callback(new Error(this.$t('commonInfo.positiveIntegerLimit')))
					} else {
						callback()
					}
				};
				// 自定义验证箱序号规则，只能填写正数字和-和,  数字不能超过已填写总箱数，不能出现--和,,
				const validateCartonIndex = (rule, value, callback) => {
					let reg = /[^0-9,-]\d*$/g
					let reg2 = /--|-,|,,|,-/g
					if (reg.test(value) || reg2.test(value) || value.slice(0, 1) == '-' || value.slice(0, 1) == ',' ||
						value.slice(-1) == '-') {
						callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexTips')))
					} else {
						// 验证是否出现4-6-8 或者 8-6的情况
						let isErrorString = false, isErrorNumber = false;
						let firstArr = value.split(',');
						if(firstArr.length > 0){
							firstArr.forEach(item => {
								let secArr = item.split('-');
								if(secArr.length > 2){// 4-6-8多个-情况
									isErrorString = true;
								}else if(secArr.length == 2){
									if(parseInt(secArr[0]) > parseInt(secArr[1])){
										isErrorNumber = true;
									}
								}
							})
						}
						if(isErrorString){
							callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexTips')))
						}else if(isErrorNumber){
							callback(new Error(this.$t('chinaWhB2BOrderPage.inputCartonIndexNumTips')))
						}else{
							callback()
						}
					}
				}
				return {
					destType: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('chinaWhB2BOrderPage.destType'),
						trigger: 'blur'
					}],
					destWh: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.deliveryWarehouse'),
						trigger: 'blur'
					}],
					destCity: [{
						required: true,
						message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.city'),
						trigger: 'blur'
					}],
					destWhAddress: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.deliveryDest'),
						trigger: 'blur'
					}],
					fbaNo: [{
							required: true,
							message: this.$t('commonInfo.FBAExample'),
							trigger: 'blur'
						},
						{
							min: 12,
							max: 12,
							message: this.$t('commonInfo.fbaNoLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
							trigger: 'blur',
						}
					],
					fbaTrackingNo: [{
							required: true,
							message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.fbaNoCode'),
							trigger: 'blur'
						},
						{
							min: 8,
							max: 8,
							message: this.$t('commonInfo.fbaNoCodeLimit'),
							trigger: 'change'
						}, {
							validator: validateCode,
						 trigger: 'blur',
						}
					],
					fbnNo: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.asnNo'),
						trigger: 'blur'
					}, {
						validator: validateCode,
						trigger: 'blur',
					}],
					reservationTime: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.deliveryDateTips'),
						trigger: 'blur'
					}],
					consignee: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contactMan'),
						trigger: 'blur'
					}],
					contactTel: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.contact'),
						trigger: 'blur'
					}],
					pcsNum: [{
						required: true,
						message: this.$t('tipsInfo.inputPlaceholder') + this.$t('chinaWhB2BOrderPage.cartonTotalPcs'),
						trigger: 'blur'
					}, {
						validator: validateNum,
						trigger: 'blur',
					}],
					packageIndexString: [{
						required: true,
						message: this.$t('chinaWhB2BOrderPage.inputCartonIndexTips'),
						trigger: 'blur'
					}, { // 正则，判断输入箱序号格式是否正确
						validator: validateCartonIndex,
						trigger: 'blur',
					}]
				}
			}
		},
		//监控data中的数据变化
		watch: {
			'form.destCountry': {
				immediate: true,
				handler(val) {
					this.showTwo = false;
					this.showThree = false;
					this.form.warehouseType = '';
					this.warehouseTypeList = [];
					if (val != '' && val != null) {
						if (val == 'SA') { //沙特
							this.warehouseTypeList = [{
								name: "FBA",
								value: '1',
							}, {
								name: "NOON",
								value: '2',
							}, {
								name: "其他海外仓/私人地址",
								value: '5',
							}]
							this.form.destCountryId = 2;
							this.isSa = '1';
						} else {
							this.showType = false;
							this.warehouseTypeList = [{
								name: "FBA",
								value: '1',
							}, {
								name: "NOON",
								value: '2',
							}, {
								name: "FBC(阿联酋-飞坦海外仓)/私人地址",
								value: '5',
							}]
							this.form.destCountryId = 3;
							this.isSa = '2';
						}
						this.showOne = true;
					} else {
						this.showOne = false;
					}
				}
			},
			'form.warehouseType': {
				immediate: true,
				handler(val) {
					this.selectWhList = [];
					this.form.deliveryType = '';
					this.caseNum = 0;
					this.caseData = [];
					this.caseDataCustom = [];
					if (val != '' && val != null) {
						this.getWhDict(val); //获取仓库信息
						if (val == '5' && this.form.destCountry == 'SA') {
							this.showType = true;
						} else {
							this.showType = false;
						}
						if (val == '6') { // 转单小包
							this.getSaInfo();
							this.showThree = true;
						}
						//console.log('ss' + val);
						this.showTwo = true;
					} else {
						this.showTwo = false;
						this.showThree = false;
					}
					this.form.destWhNo = '';
					this.form.otherDest = '';
				}
			},
			'form.deliveryType': {
				immediate: true,
				handler(val) {
					this.form.contacts = '';
					this.form.contactsTel = '';
					if (val == '1') {
						if (this.selectWhList.length > 0) {
							this.form.destWhNo = this.selectWhList[0].dictValue
						}
						this.editCity = false;
						this.form.destCity = '';
						this.showTwo = true;
						this.showThree = true;
					} else if (val == '2') {
						this.editCity = true;
						let list = this.saCityList.filter(item => item.dictValue == '1');
						this.form.destCity = list[0].dictLabel;
						this.getSaInfo();
						this.showThree = true;
					}
					// this.form.destWhNo = '';
					// this.form.otherDest = '';
				}
			},
			'form.destWhNo': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						this.clearInfo();
						this.getDestInfo(val);
						if (val == 'FBC(阿联酋-飞坦海外仓)') { // 为该海外仓则不显示联系信息
							this.showContactInfo = false;
						} else {
							this.showContactInfo = true;
						}

						this.showThree = true;
					} else {
						if (this.form.warehouseType != '6') {
							this.showThree = false;
						} else {
							this.showThree = true;
						}

					}

				}
			},

			// 海运订单目的地信息创建监听
			'formList.destCountry': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						if (this.isSa == '') { // 第一次选择
							this.isSa = val;
						} else { // 更改国家提示清空目的地信息
							if (this.isSa != val) {
								if (this.seaOrderDestList.length > 0) {
									this.handleClearSeaOrderDestInfo(val);
								} else {
									this.isSa = val;
								}
							}
						}
					}
				}
			},
			"seaDestForm.destType": {
				immediate: true,
				handler(val) {
					this.showSeaDestInfo = false;
					this.seaDestForm.destWh = '';
					this.selectWhList = [];
					if (val != '' && val != null) {
						this.getWhDict(val);
					}
				}
			},
			'seaDestForm.destWh': {
				immediate: true,
				handler(val) {
					if (val != '' && val != null) {
						this.getSeaOrderDestInfo(val);
						this.showSeaDestInfo = true;
					}
				}
			}
		},
		components: {
			SingleUpload,
			ElImageViewer,
			SingleUploadPDF,
		},
		//方法集合
		methods: {
			// tab标签选项
			handleClick(tab) {
				if (tab.name == "first") {
					this.getlist();
				}
				if (tab.name == "second") {
					this.getSeaOrderList();
				}

			},
			// 分页条数
			handleSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getlist();
			},
			// 分页页数
			handleCurrentChange(page) {
				this.queryParams.page = page;
				this.getlist();
			},
			// 获取字典数据
			async getDict() {
				// 获取转单小包模板
				const res = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_template_url'
					}
				});

				if (res.code === 200) {
					let that = this;
					let data = res.rows;
					data.forEach(item => {
						if (item.dictLabel == 'B2B转单小包上传文件模板') {
							that.modelUrl = item.remark;
						}
						if (item.dictLabel == 'FBA清单附件模板') {
							that.airTemplate.fba = item.remark;
						}
						if (item.dictLabel == 'NOON清单附件模板') {
							that.airTemplate.noon = item.remark;
						}
						if (item.dictLabel == '海外仓清单附件模板') {
							that.airTemplate.owh = item.remark;
						}
					})
				} else {
					this.$message.error(res.msg);
				}

				// 获取海运清单模板
				const resTwo = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_template_url'
					}
				});

				if (resTwo.code === 200) {
					let that = this;
					let data = resTwo.rows;
					data.forEach(item => {
						if (item.dictLabel == 'B2B海运清单模板') {
							that.detailedUrl = item.remark;
						}
					})
				} else {
					this.$message.error(res.msg);
				}
				// 获取海运国内仓库地址
				const resWh = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_cn_consignor_wh'
					}
				});
				if (resWh.code === 200) {
					this.consignorType = resWh.rows;
				} else {
					this.$message.error(resWh.msg);
				}
				// 获取B2B空运城市地址 sys_b2bAirCity_list
				const cityRes = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_b2bAirCity_list'
					}
				});
				if (cityRes.code === 200) {
					this.saCityList = cityRes.rows;
				} else {
					this.$message.error(cityRes.msg);
				}

			},
			// 根据状态码显示信息 中英文
			returnStatusName(row) {
				let name = '';
				switch (row.status) {
					case '101':
						name = this.$t('B2BStatus.creating');
						break;
					case 'n100':
						name = this.$t('B2BStatus.init');
						break;
					case 'n101':
						name = this.$t('B2BStatus.warehousing');
						break;
					case 'n200':
						name = this.$t('B2BStatus.cnReceive');
						break;
					case 'n240':
						name = this.$t('B2BStatus.cnPartialShipment');
						break;
					case 'n300':
						name = this.$t('B2BStatus.cnOutscan');
						break;
					case '-300':
						name = this.$t('B2BStatus.cnOutscanExceptionPart');
						break;
					case '-302':
						name = this.$t('B2BStatus.cnTransitExceptionCheck');
						break;
					case 'n301':
						name = this.$t('B2BStatus.cnAir');
						break;
					case '-305':
						name = this.$t('B2BStatus.cnTransitException');
						break;
					case '307':
						name = this.$t('B2BStatus.cnTransitExceptionOr');
						break;
					case '-310':
						name = this.$t('B2BStatus.cnAirExceptionDelay');
						break;
					case '-311':
						name = this.$t('B2BStatus.cnAirExceptionCancel');
						break;
					case 'n302':
						name = this.$t('B2BStatus.airArrived');
						break;
					case 'n400':
						name = this.$t('B2BStatus.customsClearanceCompLated');
						break;
					case '-401':
						name = this.$t('B2BStatus.customsClearanceComplatedExceptionCheck');
						break;
					case '-402':
						name = this.$t('B2BStatus.customsClearanceComplatedExceptionDeduction');
						break;
					case 'n510':
						name = this.$t('B2BStatus.overseasArrived');
						break;
					case 'n500':
						name = this.$t('B2BStatus.ksaInscan');
						break;
					case '-520':
						name = this.$t('B2BStatus.cartonToSku');
						break;
					case 'n699':
						name = this.$t('B2BStatus.ksaReadyForDispatch');
						break;
					case 'n700':
						name = this.$t('B2BStatus.delivering');
						break;
					case '-700':
						name = this.$t('B2BStatus.deliveryFailed');
						break;
					case 'n710':
						name = this.$t('B2BStatus.partialreceipt');
						break;
					case 'n800':
						name = this.$t('B2BStatus.successfulDelivery');
						break;
					case '-800':
						name = this.$t('B2BStatus.signingFailed');
						break;
					case '-100':
						name = this.$t('B2BStatus.cancelOrder');
						break;
					case '-600':
						name = this.$t('B2BStatus.transferThirdpartyDelivery');
						break;
						// 新加的轨迹先暂时显示后台返回的状态名称
					default:
						name = row.memo;
						break;
				}
				return name
			},
			// 是否显示展开信息 根据增加classname去区分判断显示展开按钮
			setClassName({
				row,
				index
			}) {
				let classname = 'expand';
				// 默认不显示展开列，在操作列控制显隐 zpy 2022-8-5
				// if(row.subOrderList == null || row.subOrderList.length == 0){
				//     classname = 'expand';
				// }
				return classname
			},
			//创建中和已下单可以修改
			isEditOrder(row) {
				// 审核通过不可修改，（审核通过不改变订单状态，返回审核状态）
				if (!row.examineFlag && (row.status == 'n100' || row.status == '101')) {
					return true;
				} else {
					return false;
				}
			},
			//判断是否可以修改FBA号
			isEditFBA(row) {
				//派送中和全部签收部分签收不可修改
				if (row.status == 'n700' || row.status == 'n710' || row.status == 'n800') {
					return false;
				} else {
					if (row.warehouseType == '1') { //Amazon才有FBA号修改
						return true;
					} else {
						return false;
					}
				}
			},
			//获取上传FBA返回的路径
			getupdateFile(data) {
				this.updateFbxLink = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.updateFbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			//修改FBA
			async editFBA(row) {
				this.fbaOrderNo = row.esnadTobOrderNo;
				this.fbaPopup = true;
				this.fbaLoading = true;
				const res = await this.$http.get("/toborder/detail/" + this.fbaOrderNo);
				if (res.code === 200) {
					this.FBAform.destCountry = res.data.toBOrderInfo.destCountry;
					this.FBAform.destWhNo = res.data.destWhNo == "" ? this.$t('commonInfo.customeWarehouse') : res.data
						.destWhNo;
					this.FBAform.otherDest = res.data.toBOrderInfo.otherDest;
					this.FBAform.fbaNo = res.data.toBOrderInfo.fbaNo;
					this.FBAform.fbaNoCode = res.data.toBOrderInfo.fbaNoCode;
					this.FBAform.skuCount = res.data.toBOrderInfo.skuCount;
					this.FBAform.declaredValue = res.data.toBOrderInfo.declaredValue;
					this.FBAform.packageCount = res.data.lgPackageList.length;
					this.fbaLoading = false;
				} else {
					this.fbaLoading = false;
					this.$message.error(res.msg);
				}

			},
			// 关闭修改FBA窗口
			closeFBADialog() {
				this.fbaPopup = false;
				this.updateFbxPreName = [];
				this.updateFbxLink = '';
				this.FBAform = {
					afterFbaNo: '',
					afterFbaNoCode: '',
				}
			},
			//提交修改信息
			submitFBA() {
				if (this.updateFbxLink == '') {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.dialogModifyFBANewFBATips'));
				}
				let that = this;

				let formObj = {
					orderId: this.fbaOrderNo,
					afterFbaCode: this.FBAform.afterFbaNoCode,
					afterFbaNo: this.FBAform.afterFbaNo,
					afterAttachment: this.updateFbxLink,
				}
				this.$refs['FBAInfoForm'].validate((vaild) => { //表单验证
					if (vaild) {
						that.submitFbaLoading = true;
						that.$http.post("/toborder/update/fba", formObj).then(res => {
							if (res.code == 200) {
								that.$message.success(this.$t('tipsInfo.success'))
								that.submitFbaLoading = false;
								that.closeFBADialog();
								that.getlist();
							} else {
								that.submitFbaLoading = false;
								that.$message.error(res.msg);
							}
						}).catch(err => {
							that.submitFbaLoading = false;
							that.$message.error(err.msg);
						})
					}
				})
			},
			// 获取重量信息合计
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = this.$t('commonInfo.secondTotal');
						return;
					}
					if (column.label == this.$t('commonInfo.grossWeight') + '(KG)') {
						let gwSum = 0;
						data.map(item => {
							let gwValue = item.grossWeight == null ? 0 : item.grossWeight;
							gwSum += Number((gwValue).toFixed(2));
						});
						sums[index] = gwSum == 0 ? '' : gwSum.toFixed(2);
					} else if (column.label == this.$t('commonInfo.chargeWeight') + '(KG)') {
						let cwSum = 0;
						data.map(item => {
							let cwValue = item.chargedWeight == null ? 0 : item.chargedWeight;
							cwSum += Number((cwValue).toFixed(2));
						});
						sums[index] = cwSum == 0 ? '' : cwSum.toFixed(2);
					} else {
						sums[index] = '';
					}
				});
				return sums;
			},

			//获取上传FBA返回的路径
			getFBXInfo(data) {
				this.fbxLink = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.fbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			getPDFInfo(data) {
				this.pdfLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.pdfPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			// 获取上传分拣送货清单附件
			getSortingExcel(data) {
				this.sortingSkuLink = data;
				if (data != '') {
					let str = data.split("~");
					this.sorttingFileTXTName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			rowKey(row) {
				return row.id;
			},
			// 搜索空运订单
			handleSearch() {
				if (this.orderTime != null && this.orderTime != '') {
					this.queryParams.start = this.orderTime[0];
					this.queryParams.end = this.orderTime[1];
				} else {
					this.queryParams.start = '';
					this.queryParams.end = '';
				}
				this.queryParams.page = 1;
				this.getlist();
			},
			// 重置
			handleReset() {
				this.orderTime = null;
				this.queryParams = {
					page: 1,
					limit: 10,
					orderNo: '',
					start: '',
					end: '',
				}
				this.getlist();
			},
			returnStatus(status) {
				let statusName = '';
				switch (status) {
					case '1':
						statusName = this.$t('commonInfo.productionStatusOne');
						break;
					case '2':
						statusName = this.$t('commonInfo.productionStatusTwo');
						break;
					case '3':
						statusName = this.$t('commonInfo.productionStatusThree');
						break;
					case '4':
						statusName = this.$t('commonInfo.productionStatusFour');
						break;
				}
				return statusName;
			},
			//关闭图片
			closeImg() {
				this.showImg = false;
			},
			//查看POD图片
			checkPOD(url) {
				this.imgList = [];
				//显示预览图片　
				if (url.length > 0) {
					var that = this;
					that.showImg = true;
					url.forEach(item => {
						that.imgList.push(item);
					})
				} else {
					this.$message.error(this.$t('tipsInfo.noPodInfo'));
				}

			},
			isShowPDF(row) {
				let isShow = false;
				if (this.frontOrafter == '0') { //前置审核
					if (row.examineFlag == false) { //未审核
						isShow = false;
					} else { //1审核通过
						isShow = true;
					}
				} else { // 1后置审核
					isShow = true;
				}
				return isShow;
			},
			//添加一条箱号数据
			addCaseItem(item) {
				let num1 = item.input.substr(-3);
				if (this.isEdit && this.caseDataCustom.length > 0) { //修改时再增加箱数
					num1 = this.caseDataCustom[this.caseDataCustom.length - 1].nextNum.split('-')[1];
				}
				let num2 = ('00' + (parseInt(num1) + 1)).substr(-3);
				this.getCaseNum(1, num2)
			},
			// 增加custom箱号数据
			addCustomCaseItem(item) {
				let num = ('00' + (parseInt(item.nextNum.split('-')[1]) + 1)).substr(-3);
				this.caseDataCustom.push({
					preNum: item.preNum,
					input: '',
					nextNum: item.nextNum.split('-')[0] + '-' + num,
					isNull: false,
					color: 'white',
				})
			},
			//删除选择的箱号数据
			delCaseItem(row, index) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							this.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: this.form.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let indexs = this.caseData.findIndex(
											item => item.index === row.index
										);
										console.log(indexs);
										this.caseData.splice(indexs, 1);
										this.caseNum--;
										this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
									} else {
										this.$message.error(res.msg);
									}
									if (this.caseData.length == 0) {
										this.getCaseNum(1, '');
									}
								});
						} else {
							this.caseData.splice(index, 1);
							this.caseNum--;
							this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
							if (this.caseData.length == 0) {
								this.getCaseNum(1, '');
							}
						}


					})
					.catch(() => {});
			},
			//删除选择的箱号custom数据
			delCaseCustomItem(row, index) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 判断是不是编辑
						if (row.isDetail) {
							this.$http
								.delete("/toborder/delete/lgpackage", {
									data: {
										esnadTobOrderNo: this.form.orderNo,
										esnadLgPackageNo: row.order
									}
								})
								.then(res => {
									if (res.code === 200) {
										let customIndex = this.caseDataCustom.findIndex(
											item => item.index === row.index
										);
										this.caseDataCustom.splice(customIndex, 1);
										this.caseNum--;
										this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
									} else {
										this.$message.error(res.msg);
									}
									if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
										this.getCaseNum(1, '');
									}
								});
						} else {
							this.caseDataCustom.splice(index, 1);
							this.caseNum--;
							this.$message.success(this.$t('tipsInfo.deleteSuccessTips'));
							if (this.caseData.length == 0 && this.caseDataCustom.length == 0) {
								this.getCaseNum(1, '');
							}
						}

					})
					.catch(() => {});
			},
			// 自动填充该箱填写信息到后续箱信息
			autoFillCaseInfo(data, i) {
				let info = this.caseDataCustom[i].input;
				this.caseDataCustom.forEach((item, index) => {
					if (index >= i) {
						item.input = info;
					}
				})
				this.handleInputCustomCase();
			},
			// 监听自定义箱号输入信息
			handleInputCustomCase() {
				// 点击提交后才监听每一次的输入是否正确
				if (this.isHandleSubmit) {
					this.handleCheckCaseInfo(this.caseDataCustom);
				}

			},

			// 追踪页面
			async xiang(id) {
				// this.$router.push({ path: "/Tracking?id=" + id });
				const res = await this.$http.get(
					"/toborder/trackinfo/" + id
				);
				this.rowOrderNo = id;
				if (res.code === 200) {
					this.activities = res.data;
					this.mainActiveData = this.activities.mainOrderTrackingList;
					this.subActiveData = this.activities.subOrderTrackingList;
					// 增加分批轨迹信息 如果有返回子订单轨迹则再主订单轨迹入库完成后面添加对象，来显示子订单分批信息 zpy 2022-7-29
					if (this.subActiveData.length > 0) {
						let index = this.mainActiveData.findIndex((item) => item.status == 'n200');
						this.mainActiveData.splice(index, 0, {
							initTime: this.$t('chinaWhB2BOrderPage.batchTrackingTitle'),
							status: 'batchInfo',
						})
						this.subActiveData.forEach(item => {
							item.forEach(subItem => {
								subItem.initTime = subItem.initTime + ' Time Zone：Beijing';
							})

						})
					}

					this.popups = true;
					if (this.mainActiveData.length === 0) {
						this.popups = false;
						//输入的订单号不存在
						this.$message.error(this.$t('tipsInfo.orderNoExistTips'));
					}
					this.mainActiveData.forEach(item => {
						if (item.status == '1000') {
							this.dlvdMainDeliveryTime = item.description
						}
						if (item.status == 1000 && item.id) {
							this.warehouseTime = item.description;
							this.onRemarks = item.remarks;
							this.isShow = true;
						}
						if (item.initTime != this.$t('chinaWhB2BOrderPage.batchTrackingTitle')) {
							item.initTime = item.initTime + ' Time Zone：Beijing';
						}
						// item.initTime = formatDate(item.initTime);

						// let indexLocation = item.location.indexOf(":");
						// item.location = item.location.substring(
						// 	indexLocation + 1,
						// 	item.location.length
						// );

						// let indexRemarks = item.remarks.indexOf(":");
						// item.remarks = item.remarks.substring(
						// 	indexRemarks + 1,
						// 	item.remarks.length
						// );
					});


				}
			},
			//清单附件下载
			skuSheet(url) {
				window.open(url);
			},
			// 下载PDF面单
			async expressSheet(row) {
				let res = await this.$http.get(
					"/toborder/download/waybill/" + row.esnadTobOrderNo
				);
				if (res.code === 200) {
					window.open(res.data, "_blank");
				} else {
					this.$message.warning(res.msg);
				}
			},
			isShowPod(row) { //判断数据状态
				let isShow = false;
				let statusNumber = row.status.substr(1);
				// 只有部分签收和全部签收 才能显示POD
				if (parseInt(statusNumber) == 710 || parseInt(statusNumber) == 800) {
					if (row.podLink != null && row.podLink != '') {
						isShow = true;
					}
				}
				return isShow
			},
			// 上传文件
			getExcel(data) {
				this.skuListLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: data,
					}]
					//如果是noon订单，则显示分拣送货清单附件按钮
					if (this.form.warehouseType == "2") {
						this.isSorting = true;
					} else {
						this.isSorting = false;
					}
				} else {
					this.isSorting = false;
				}
			},
			//获取品名列表
			async getKindNameList() {
				const res = await this.$http.get("/toborder/productname/list");
				if (res.code == 200) {
					if (res.data.length >= 0) {
						res.data.forEach((item, index) => {
							this.kindNameList.push({
								id: item.id,
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
						})
						this.kindNameList = this.quchong(this.kindNameList);
					}
				}
			},
			closeDialog() {
				this.kindNamePopup = false;
			},
			//去重数组，以中文描述为准
			quchong(arr) {
				const res = new Map();
				return arr.filter(
					(list) => !res.has(list.productChineseDesc) && res.set(list.productChineseDesc, 1)
				);
			},
			//自提则填充飞坦海外仓信息 只填充仓库和地址
			async getSaInfo() {
				//获取字典的飞坦海外仓信息
				await this.$http.get("/toborder/system/dict/data/list?dictType=sys_SA_FullfillmentWh").then(res => {
					if (res.code == 200) {
						if (this.form.warehouseType == '6') {
							this.SelfDestWhNo = res.rows[0].dictValue;
							let info = res.rows[0].remark.split('###');
							this.SelfOtherDest = info[0];
						} else {
							this.SelfDestWhNo = '自提/Self Pickup';
							let info = res.rows[0].remark.split('###');
							this.SelfOtherDest = info[0];
							this.editContactInfo = false;
						}
					}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			//返回是否显示仓库信息
			returnShowDestWh() {
				let isShow = true;
				if (this.form.destCountry != '' && this.form.destCountry != 'AE') {
					if (this.showTwo) {
						if (this.form.warehouseType == '5') { //排除FBA NOON
							if (this.form.deliveryType == '2' || this.form.deliveryType == '') { // 自提不显示
								isShow = false;
							}
						}
						if (this.form.warehouseType == '6') { // 转单小包不需要显示，直接填充飞坦海外仓信息
							isShow = false;
						}
					} else { //排除按层级显示
						isShow = false;
					}
				} else if (this.form.destCountry == 'AE') {
					isShow = true;
				} else {
					isShow = false;
				}
				return isShow;
			},
			// 返回是否显示仓库地址信息
			returnShowOtherDest() {
				let isShow = true;
				if (this.form.destCountry == 'SA') {
					if (this.form.warehouseType == '5') { //排除FBA NOON
						if (this.form.deliveryType == '2' || this.form.deliveryType == '') { // 自提不显示
							isShow = false;
						}
					}
					if (this.form.warehouseType == '6') { // 转单小包不需要显示，直接填充飞坦海外仓信息
						isShow = false;
					}
				} else if (this.form.destCountry == 'AE') {
					if (this.form.warehouseType == '') {
						isShow = false;
					}
				} else {
					isShow = false;
				}
				return isShow;
			},
			//获取仓库数据
			async getWhDict(type) {
				let dictText = '';
				switch (type) {
					case '1':
						dictText = this.isSa == '1' ? 'sys_SA_FBAWh' : 'sys_AE_FBAWh';
						this.fileTip = 'FBA外箱箱唛';
						this.tipInfo = '该订单只能对应一个FBA号，且下单前有明确的FBA号和FBA外箱箱唛则选择此种类型，该订单由我司预约派送时间';
						break;
					case '2':
						dictText = this.isSa == '1' ? 'sys_SA_NOONWh' : 'sys_AE_NOONWh';
						this.fileTip = 'NOON的送仓文件';
						this.tipInfo = '若该订单下单前明确获知ASN号且该订单只能对应一个ASN号，选择此类型';
						break;
					case '3':
						dictText = 'sys_SA_FleetanWh';
						this.tipInfo =
							'备注：1.若由于FBA库容不足导致下单前无法获悉明确的FBA号 2.NOON/SB2B下单前无法创建ASN号，但是能明确该订单是整个订单一次性派送，以上2种情况选择此种类型，免仓储期10个自然日（到达海外仓开始计算）。';
						break;
					case '4':
						dictText = 'sys_SA_FullfillmentWh';
						this.tipInfo = '若该订单需要用到我司海外仓上架履单，或者送NOON/SB2B的货物不是一整个订单一次派送则选择此种类型';
						break;
					case '5':
						dictText = this.isSa == '1' ? 'sys_SA_OtherWh' : 'sys_AE_OtherWh';
						this.tipInfo = '该订单只能对应一个送仓地址（其他海外仓或私人地址），选择此种类型，若该订单重量少于10KG，建议选择页面左侧B2C下单模式';
						break;
				}
				await this.$http.get("/toborder/system/dict/data/list?dictType=" + dictText).then(res => {
					if (res.code == 200) {
						this.selectWhList = res.rows;
						if (!this.isEdit) {

							// if (type == '3' || type == '4' || type == '5') { //海外仓直接默认第一项
							// 	this.form.destWhNo = this.selectWhList[0].dictValue
							// };
						}
					} else {}
				}).catch(err => {
					this.$message.error(err.msg);
				})
			},
			//选择仓库时自定填充仓库地址信息
			getDestInfo(val) {
				let that = this;
				that.form.otherDest = '';
				that.form.contacts = '';
				that.form.contactsTel = '';
				that.editOtherDest = false;
				that.editContactInfo = false;
				setTimeout(function() {
					that.selectWhList.forEach(item => {
						if (item.dictValue == val) {
							if (that.form.warehouseType == '5') { //如果是海外仓，则获取字典存储的地址###联系人###联系电话信息
								// 自定义海外仓可编辑，其他私人地址不可编辑
								if (val != 'custom') {
									if (item.remark != '' && item.remark != null) {
										let info = item.remark.split('###');
										that.form.otherDest = info[0];
										that.form.contacts = info[1];
										that.form.contactsTel = info[2];
										if (that.form.contacts == 'Fleetan-') {
											that.form.contacts = that.form.contacts + that.prefixAwbNo;
										}
										that.editOtherDest = true; //不可编辑地址，直接填充
										that.editContactInfo = true; //不可编辑联系人联系方式
										that.editCity = true; //不是自定义海外仓不可修改城市(默认RUH)

										// 沙特海外仓仓库默认都在利雅得城市
										if (that.isSa == '1') {
											let list = that.saCityList.filter(item => item.dictValue ==
												'1');
											that.form.destCity = list[0].dictLabel;
										} else { // 阿联酋暂时没有仓库，城市默认为空
											that.form.destCity = '';
										}

									}
								} else {
									// 沙特自定义海外仓可以选择城市信息
									that.editCity = false;
									that.form.destCity = '';
								}
							} else { // FBA NOON
								that.form.otherDest = item.remark;
								that.editOtherDest = true; //不可编辑地址，直接填充
								// 选择FBA和NOON 暂时不上传城市信息
								that.form.destCity = '';
							}

						}
					})
					that.loading = false;
				}, 300)
			},
			clearInfo() {
				this.form.fbaNo = '';
				this.form.fbaNoCode = '';
				this.form.asnNo = '';
				this.form.contacts = '';
				this.form.contactsTel = '';

				this.fbxLink = ''; //FBX上传文件路径
				this.fbxPreName = [];
				this.pdfLink = ''; //PDF上传文件路径
				this.pdfPreName = [];
				this.skuListLink = '';
				this.sortingSkuLink = '';
				this.fileTXTName = [];
				this.sorttingFileTXTName = [];
				this.isSorting = false;
				this.uploadSuccess = false;
				this.isHandleSubmit = false;
				this.caseNum = 0;
				this.caseData = [];
				this.caseDataCustom = [];
			},
			openKindName() {
				this.addKindNameList = [];
				this.addItem();
				this.kindNamePopup = true;
			},
			// 动态添加一行
			addItem() {
				this.addKindNameList.push({
					productChineseDesc: "",
					productEnglishDesc: "",
				});
			},
			// 其他杂费删除一行
			delItem(index) {
				this.addKindNameList.splice(index, 1);
			},
			//新增品名
			submitNewKindName() {

				let isEmpty = false;
				let kindNameData = [];
				this.addKindNameList.forEach((item, index) => {
					if (item.productChineseDesc != '' && item.productEnglishDesc != '') {
						kindNameData.push(item);
					}
					if (item.productChineseDesc != '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc != '') {
						isEmpty = true;
					}
					if (item.productChineseDesc == '' && item.productEnglishDesc == '') {
						isEmpty = true;
					}
				})
				if (isEmpty) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.dialogAddKindnameSubmitTips'));
				}
				this.addKindNameLoading = true;
				this.$http.post("/toborder/productname/add", kindNameData).then(res => {
					if (res.code == 200) {
						kindNameData.forEach((item, index) => {
							this.kindNameList.push({
								id: index + '_add',
								value: item.productChineseDesc,
								label: item.productChineseDesc,
								productChineseDesc: item.productChineseDesc,
								productEnglishDesc: item.productEnglishDesc
							})
							this.kindName.push(item.productChineseDesc);
						})
						this.addKindNameLoading = false;
						this.$message.success(this.$t('tipsInfo.success'));
						this.kindNamePopup = false;
					} else {
						this.addKindNameLoading = false;
						this.$message.error(this.$t('tipsInfo.failed'));
					}
				}).catch(err => {
					this.addKindNameLoading = false;
				})
			},

			// 添加订单
			addOrder() {
				this.caseNum = 0;
				this.isEdit = false;
				this.formData = [];
				this.orderTitle = this.$t('commonInfo.createAirOrder');
				this.orderPopup = true;
				this.getOrder();

			},
			// 批量下单
			async batchCreateOrder(){
				this.batchCreateOrderPopup = true;
				// 获取模板下载地址
				const res = await this.$http.get("/toborder/system/dict/data/list", {
					params: {
						page: 1,
						limit: 100,
						dictType: 'sys_template_url'
					}
				});
				if (res.code === 200) {
					let data = res.rows;
					data.forEach(item => {
						if(item.dictLabel == 'B2B批量下单上传信息模板'){
							this.batchCreateOrderModulUrl = item.remark;
						}
					})
				} else {
					this.$message.error(res.msg);
				}
			},
			
			// 修改订单
			edit(row) {
				this.isEdit = true;
				this.form.orderNo = row.esnadTobOrderNo;
				this.orderTitle = this.$t('commonInfo.modifyAirOrder');
				this.orderPopup = true;
				this.getOrderInfo(row.esnadTobOrderNo);
			},

			// 获取箱数据 通过组合表格展开
			async changeEve(row) {
				if (this.arr[0]) {
					if (this.arr[0] === row.id) {
						return (this.arr = []);
					}
				}
				this.arr = [];
				const res = await this.$http.get(
					"/toborder/clientlgpackage/info/" + row.esnadTobOrderNo
				);

				if (res.code === 200) {
					row.datas = res.data;

					let obj = Object.assign({}, row);
					let oIndex = this.tableData.findIndex(item => item.id == row.id);
					this.$set(this.tableData, oIndex, obj);
					this.arr = [row.id];
				} else {
					this.$message.error(res.msg);
				}
			},
			// 通过点击按钮显示箱信息弹窗
			handleCheckPackageInfo(row) {
				this.packageData = [];
				this.packageDialogPopup = true;
				this.checkPackageOrderNo = row.esnadTobOrderNo;
				this.getPackageList(row.esnadTobOrderNo);
			},
			// 查看箱图片
			handleViewCartonImg(url) {
				this.imgList = [];
				this.imgList.push(url);
				this.showImg = true;
			},
			// 获取箱信息列表
			async getPackageList(id) {
				this.packageListLoading = true;
				const res = await this.$http.get(
					"/toborder/clientlgpackage/info/" + id
				);
				if (res.code == 200) {
					this.packageData = res.data;
					this.packageListLoading = false;
				} else {
					this.$message.error(res.msg);
					this.packageListLoading = false;
				}
			},
			// 获取列表
			async getlist() {
				this.loading = true;
				this.tableData = [];
				const res = await this.$http.get("/toborder/customer", {
					params: this.queryParams
				});

				if (res.code === 200) {
					let data = res.data.list;
					// 总共有多少条数据，分页
					this.total = res.data.totalCount;
					let index = 1;
					data.forEach(item => {
						item.id = index += 1;
						item.expand = false; //默认关闭
						item["datas"] = [];
						item.initTime = formatDate(item.initTime);
					});
					this.tableData = data;
					this.loading = false;
				} else {
					this.$message.error(res.msg);
					this.loading = false;
				}
			},
			// 弹出层x号关闭
			handleDialogClose() {
				// alert(1);
				this.popups = false;
				this.orderPopup = false;
				this.isShow = false;
				this.warehouseTime = "";
				this.onRemarks = "";
				this.caseData = [];
				this.showContactInfo = true;
				this.caseDataCustom = [];
				this.isSa = '';
				this.form = {
					destCountry: '', //国家
					destCity: '', //城市
					destCountryId: 2,
					warehouseType: '',
					destWhNo: '', //仓库
					otherDest: '', //地址
					orderNo: '', //订单编号
					fbaNo: '', //FBA号
					fbaNoCode: '', //FBA跟踪编码
					asnNo: '', //noon流程的预约号
					deliveryDate: '', //预约送仓时间
					contacts: '', //自定义海外仓 联系人
					contactsTel: '', //自定义海外仓 联系电话
					declaredValue: 0, //申报金额
					skuCount: 0, //SKU总数
					deliveryType: '', //派送方式，1送货上门，2仓库自提
					execllist: [], //转单小包信息
					customsDeclarationFlag: false, //是否报关退税 默认false
					clientOrderNo: '', //客户订单号，非必填
				};
				this.showType = false; //隐藏派送方式
				this.kindName = [];
				this.skuListLink = '';
				this.fileTXTName = [];
				this.dlvdMainDeliveryTime = '';
				this.isHandleSubmit = false;
			},
			handleCloseDetail() {
				this.detailPopup = false;
				this.url = '';
				this.createErrorInfo = '';
				// 关闭更新列表，同步生成的面单链接
				this.getlist();
			},
			//FBA附件下载
			FBAsheet(row) {
				window.open(row);
			},
			PDFSheet(row) {
				if (row == null) {
					this.$message.warning(this.$t('chinaWhB2BOrderPage.downloadPdfFailedTips'));
				} else {
					window.open(row);
				}
			},
			// 点击展开或收起分批信息
			handleExpandRow(row) {
				row.expand = row.expand ? false : true;
				this.$refs.b2bOrderTable.toggleRowExpansion(row);
			},
			// 列表更多操作
			handleCommand(val) {
				let row = val.row;
				// 判断操作按钮
				switch (val.command) {
					// 修改订单
					case 'a':
						this.edit(row);
						break;
						// 修改FBA信息
					case 'b':
						this.editFBA(row);
						break;
						// 下载清单
					case 'c':
						this.FBAsheet(row.skuListLink);
						break;
						// 显示分批信息
					case 'd':
						this.handleExpandRow(row);
						break;
				}
			},
			//限制输入数字
			handleNum() {
				if (this.form.warehouseType == '1' && this.form.fbaNo == '') {
					this.caseNum = '';
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.createClientPackageNoInputFBATips'));
				}
				if (this.form.warehouseType == '2' && this.form.asnNo == '') {
					this.caseNum = '';
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.createClientPackageNoInputASNTips'));
				}
				this.caseNum = (this.caseNum.toString()).replace(/[^\d]/g, '');
				//防抖
				if (this.timeout) {
					clearTimeout(this.timeout)
				}
				if (this.caseNum != '') {
					this.timeout = setTimeout(() => {
						this.caseData = [];
						this.caseDataCustom = [];
						this.getCaseNum(this.caseNum, '');
					}, 800);
				}
			},
			// 获取订单号
			async getOrder() {
				const res = await this.$http.post("/toborder/create/0");
				if (res.code === 200) {
					this.form.orderNo = res.data;
				} else {
					this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
				}
			},
			// 下载清单模板
			handleDownloadUrl() {
				window.open(this.modelUrl);
			},
			//获取订单信息
			async getOrderInfo(id) {
				await this.$http.get("/toborder/detail/" + id).then(res => {
					if (res.code == 200) {
						// this.form = getObjectInfo(this.form,res.data.toBOrderInfo);
						this.form.destCountry = res.data.toBOrderInfo.destCountry;
						this.form.destCountryId = res.data.toBOrderInfo.destCountryId;


						//级联关系，定时赋值 需要按顺序赋值

						let that = this;
						setTimeout(function() {
							that.form.warehouseType = res.data.toBOrderInfo.warehouseType;
							that.form.declaredValue = res.data.toBOrderInfo.declaredValue;
							that.form.skuCount = res.data.toBOrderInfo.skuCount;
							that.form.customsDeclarationFlag = res.data.toBOrderInfo
								.customsDeclarationFlag
							that.form.destCity = res.data.toBOrderInfo.destCity;
							that.form.clientOrderNo = res.data.toBOrderInfo.clientOrderNo;
							//预选品名
							if (res.data.toBOrderInfo.productChineseDesc != null) {
								if (res.data.toBOrderInfo.productChineseDesc.split(',').length != 0) {
									that.kindName = res.data.toBOrderInfo.productChineseDesc.split(
										',');
								}
							}


							setTimeout(function() {
								that.form.deliveryType = res.data.toBOrderInfo.deliveryType;
								setTimeout(function() {
									that.form.destWhNo = res.data.destWhNo === '' ?
										'custom' : res
										.data.destWhNo;
									setTimeout(function() {
										that.form.otherDest = res.data
											.toBOrderInfo.otherDest;
										setTimeout(function() {
											that.setDetail(res.data
												.lgPackageList);
										}, 500)
										if (res.data.toBOrderInfo
											.skuListLink != null && res.data
											.toBOrderInfo.skuListLink != ''
										) { //获取清单附件名
											let str = res.data.toBOrderInfo
												.skuListLink.split("~");
											that.skuListLink = res.data
												.toBOrderInfo.skuListLink;
											that.fileTXTName = [{
												name: str[1],
												url: res.data
													.toBOrderInfo
													.skuListLink,
											}];
											// 分拣清单
											if (res.data.toBOrderInfo
												.sortOrderAddress != null &&
												res.data.toBOrderInfo
												.sortOrderAddress != '') {
												let fenjian = res.data
													.toBOrderInfo
													.sortOrderAddress.split(
														"~");
												that.sortingSkuLink = res.data
													.toBOrderInfo
													.sortOrderAddress;
												that.sorttingFileTXTName = [{
													name: fenjian[1],
													url: res.data
														.toBOrderInfo
														.sortOrderAddress,
												}];
												that.isSorting = true;
											}
										}
										if (that.form.warehouseType == '3' ||
											that.form
											.warehouseType == '4' || that.form
											.warehouseType == '5') {
											that.form.contacts = res.data
												.toBOrderInfo
												.contacts;
											that.form.contactsTel = res.data
												.toBOrderInfo
												.contactsTel;
											that.loading = false;
										}

										if (that.form.warehouseType == '2') {
											that.form.asnNo = res.data
												.toBOrderInfo.asnNo;
											that.form.deliveryDate = res.data
												.toBOrderInfo.deliveryTime;
											that.pdfLink = res.data
												.tobOrderAttachmentInfoEntity
												.pdf;
											//修改upload的preview预览
											if (that.pdfLink != '') { //清楚附件时
												//修改upload的preview预览
												let str = res.data
													.tobOrderAttachmentInfoEntity
													.pdf
													.split("~");
												that.pdfPreName = [{
													name: str[str
														.length - 1
													],
													url: res.data
														.tobOrderAttachmentInfoEntity
														.pdf,
												}]
											}
											that.loading = false;
										} else if (that.form.warehouseType ==
											'1') {
											that.form.fbaNo = res.data
												.toBOrderInfo.fbaNo;
											that.form.fbaNoCode = res.data
												.toBOrderInfo
												.fbaNoCode;
											that.fbxLink = res.data
												.tobOrderAttachmentInfoEntity
												.attachment;
											if (that.fbxLink != '') {
												//修改upload的preview预览
												let str = that.fbxLink.split(
													"~");
												that.fbxPreName = [{
													name: str[str
														.length - 1
													],
													url: that.fbxLink,
												}]
											}
											that.loading = false;
										} else if (that.form.warehouseType ==
											'6') {
											that.uploadSuccess = true;
										}
									}, 400)
								}, 400)
							}, 400)
						}, 400);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 修改循环添加 table
			setDetail(OrderCaseList) {
				let that = this;
				that.caseData = [];
				that.caseNum = OrderCaseList.length;
				if (OrderCaseList != null) {
					if (OrderCaseList.length > 0) {
						that.isCaseNormal = true;
						OrderCaseList.forEach((item, i) => {
							that.caseData.push({
								index: i,
								input: item.clientLgPackageNo,
								order: item.esnadLgPackageNo,
								isDetail: true
							});
						});
					} else { //如果是空的箱集合，则根据输入的箱数自动填充箱号
						that.caseLoading = false;
						that.getCaseNum(1, '');
					}
				} else { //如果是空的箱集合，则根据输入的箱数自动填充箱号
					that.caseLoading = false;
					that.getCaseNum(1, '');
				}

			},
			//amazon:填写FBA号：FBA号U001，未填写FBA号：FBX-年月日订单号后四位-001
			//noon:需要判断是否已经填写ASN号，填写：asn号-001，未填写：NOON-年月日订单号后四位-001
			//自定义海外仓：CUSTOM-年月日订单号后四位-001
			getCaseNum(caseNum, addNum) {
				this.caseLoading = true;
				if (!this.isEdit) {
					this.isCaseNormal = false;
					this.isCaseSpecial = false;
				}

				if (caseNum <= 0) {
					caseNum = 1;
				}
				let nowTime = new Date();
				let caseTime = nowTime.getFullYear().toString() + (nowTime.getMonth() + 1).toString() + nowTime.getDate()
					.toString() + nowTime.getHours().toString() + nowTime.getMinutes().toString() + nowTime.getSeconds()
					.toString();
				for (let i = 0; i < caseNum; i++) {
					let num = '';
					if (addNum == '') {
						num = ('00' + (i + 1)).substr(-3);
					} else {
						num = addNum;
					}
					switch (this.form.warehouseType) {
						case '1':
							// FBA 默认
							this.isCaseNormal = true;
							this.caseData.push({
								input: this.form.fbaNo + 'U000' + num,
							});
							break;
						case '2':
							this.isCaseNormal = true;
							this.caseData.push({
								input: this.form.asnNo + '-' + num,
							});
							break;
						case '5':
							if (this.isSpecial == true) {
								this.isCaseSpecial = true;
								this.caseDataCustom.push({
									preNum: this.prefixAwbNo,
									input: this.form.clientOrderNo,
									nextNum: this.form.orderNo.substr(-4) + '-' + num,
									isNull: false,
									color: 'white',
								})
							} else {
								this.isCaseNormal = true;
								this.caseData.push({
									input: this.prefixAwbNo + this.clientEnName + this.form.orderNo.substr(-4) +
										'-' +
										num,
								});
							}
							break;
						case '6': // 转单小包生成箱号规则与海外仓一样
							if (this.isSpecial == true) {
								this.isCaseSpecial = true;
								this.caseDataCustom.push({
									preNum: this.prefixAwbNo,
									input: '',
									nextNum: this.form.orderNo.substr(-4) + '-' + num,
								})
							} else {
								this.isCaseNormal = true;
								this.caseData.push({
									input: this.prefixAwbNo + this.clientEnName + this.form.orderNo.substr(-4) +
										'-' +
										num,
								});
							}
							break;
					}


				}
				let that = this;
				setTimeout(function() {
					//更新显示箱数
					if (that.isCaseSpecial) {
						that.caseNum = that.caseDataCustom.length;
					}
					if (that.isCaseNormal) {
						that.caseNum = that.caseData.length;
					}
				}, 100)

				this.caseLoading = false;
			},
			// 检测箱信息填写是否完整
			handleCheckCaseInfo(data) {
				let isAllRight = true;
				data.forEach((item, index) => {
					if (item.input == '') {
						item.color = '#e3cd85';
						isAllRight = false;
					} else {
						item.color = 'white';
					}
					this.$set(this.caseDataCustom, index, item);
				})
				return isAllRight;
			},
			// 检验当前订单号是否最新，防止客户打开两个浏览器且都打开下单窗口，都提交导致一个订单号出现两个面单情况
			async checkIsLastOrderNo() {
				this.DialogLoading = true;
				if(!this.isEdit){
					let currentOrderNo = this.form.orderNo;
					// 获取当前最新订单号
					const res = await this.$http.post("/toborder/create/0");
					if (res.code === 200) {
						this.form.orderNo = res.data;
						// 订单号对不上，需要更新数据
						if(currentOrderNo != this.form.orderNo){
							// 为海外仓需要更新箱号
							if(this.form.warehouseType == '5'){
								// 自定义箱号
								if(this.isSpecial == true){
									this.caseDataCustom.forEach(item => {
										let str = this.form.orderNo.substr(-4);
										let num = item.nextNum.split('-')[1];
										item.nextNum = str + '-' + num;
									})
								}else{
									this.caseData.forEach(item => {
										let str = this.prefixAwbNo + this.clientEnName + this.form.orderNo.substr(-4);
										let arr = item.input.split('-');
										let num = arr[arr.length - 1];
										item.input = str + num;
									})
								}
							}
						}
						this.submitUpdateInfo();
					} else {
						this.DialogLoading = false;
						this.$message.error(this.$t('tipsInfo.createOrderNoFailedTips'));
					}
				}else{
					this.submitUpdateInfo();
				}
			},

			submitUpdateInfo() {
				if (this.form.warehouseType == '1') { //必须上传FBX附件
					if (this.fbxLink == '' || this.fbxLink == null) {
						this.$message.warning(this.$t('tipsInfo.submitOrderFBXFileTips'));
						return;
					}
				}
				if (this.form.warehouseType == '2') { //如果是noon仓库则必须上传PDF文件
					if (this.pdfLink == '' || this.pdfLink == null) {
						this.$message.warning(this.$t('tipsInfo.submitOrderPDFFileTips'));
						return;
					}
				}
				if (this.form.warehouseType == '6') { // 转单需要附件信息
					if (this.uploadData.length == 0) {
						this.$message.warning(this.$t('tipsInfo.submitOrderPacketFileTips'));
						return;
					}
				}



				let that = this;
				//第二个接口参数
				let obj = {
					clientLgPackageList: [],
					esnadTobOrderNo: this.form.orderNo,
					clientOrderNo: this.form.clientOrderNo,
				};
				let productList = [];
				//处理品名字段
				this.formData.productChineseDesc = '';
				this.formData.productEnglishDesc = '';
				//是否上传清单 
				if (this.skuListLink == '' && this.form.warehouseType != '6') { //转单小包不需要上传清单
					return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
				} else {
					obj.skuListLink = this.skuListLink;
				}
				//分拣送货清单附件链接 非必填项
				obj.sortOrderAddress = this.sortingSkuLink;
				this.formData.skuCount = this.form.skuCount;
				this.formData.declaredValue = this.form.declaredValue;
				this.formData.customsDeclarationFlag = this.form.customsDeclarationFlag;
				if (this.kindName.length != 0) {
					this.kindName.forEach(item => {
						that.kindNameList.forEach(item1 => {
							if (item1.productChineseDesc == item) {
								that.formData.productChineseDesc += item1.productChineseDesc + ',';
								that.formData.productEnglishDesc += item1.productEnglishDesc + ',';
							}
						})
					})
				} else if (this.form.warehouseType == '6') {
					this.formData.productChineseDesc = '';
					this.formData.productEnglishDesc = '';
				} else {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderProductionTips'))
				}

				this.formData.productChineseDesc = this.formData.productChineseDesc.substr(0, this.formData
					.productChineseDesc.length - 1);
				this.formData.productEnglishDesc = this.formData.productEnglishDesc.substr(0, this.formData
					.productEnglishDesc.length - 1);
				if (this.form.skuCount <= 0 || this.form.declaredValue <= 0) {
					if (this.form.warehouseType == '6') {
						return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputPackageNumTips'))
					} else {
						return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputSkuNumberTips'))
					}
				}


				//调用接口
				//上传订单信息
				if (this.isCaseNormal) {
					if (this.caseData.length > 0) {
						this.caseData.forEach(item => {
							if (item.input.length !== 0) {
								obj.clientLgPackageList.push(item.input);
							}
						});
					}
				}
				this.isHandleSubmit = true; //验证显示
				if (this.isCaseSpecial) {
					if (this.caseDataCustom.length > 0) {
						// 特殊箱信息需要验证客户是否填写正确
						if (this.handleCheckCaseInfo(this.caseDataCustom)) {
							this.caseDataCustom.forEach(item => {
								obj.clientLgPackageList.push(item.preNum + item.input + item.nextNum);
							})
						} else {
							return this.$message.warning(this.$t('chinaWhB2BOrderPage.caseInputTips'))
						}

					}
				}

				this.orderCaseNum = obj.clientLgPackageList.length;
				if (this.orderCaseNum <= 0) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.submitOrderInputClientPackageNumTips'))
				}

				obj = Object.assign(obj, this.formData);

				if (this.form.destCountry == 'SA') {
					if (this.form.warehouseType == '5' && this.form.deliveryType == '2') {
						this.form.destWhNo = this.SelfDestWhNo;
						this.form.otherDest = this.SelfOtherDest;
					}
					if (this.form.warehouseType == '6') {
						this.form.destWhNo = this.SelfDestWhNo;
						this.form.otherDest = this.SelfOtherDest;
						this.form.execllist = this.uploadData;
					}
				}

				//第三个接口参数
				// 2022-5-17 zpy 地址路径需要编码防止乱码 
				let fileObj = {
					orderNo: this.form.orderNo,
					attachment: this.fbxLink, //FBX文件路径
					pdf: this.pdfLink, //PDF文件路径
				}
				this.$refs["orderForm"].validate(valid => {
					if (valid) {
						// this.DialogLoading = true;
						let formParams = JSON.parse(JSON.stringify(this.form));
						if (formParams.destWhNo == 'custom') { //如果选择自定义海外仓，则仓库传空值，后台判断仓库和仓库地址只取一个值，所以两个一个要设置为空
							formParams.destWhNo = '';
						}

						this.$http.post("/toborder/update/dest", formParams).then(res => {
							if (res.code == 200) {
								that.$http.post("/toborder/update/lgPackageInfo", obj).then(resTwo => {
									if (resTwo.code === 200) {
										//上传附件信息
										that.$http.post("/toborder/attachment/info/add", fileObj)
											.then(
												resThree => {
													if (resThree.code == 200) {
														that.$message.success(this.$t(
															'tipsInfo.operationSuccess'))
														that.DialogLoading = false;
														that.orderPopup = false;
														that.showType = false; //隐藏派送方式
														that.clearInfo();
														that.getlist();
														//显示详情
														that.showOrderDetail();
													} else {
														that.DialogLoading = false;
														that.$message.error(resThree.msg);
													}
												})
									} else {
										that.DialogLoading = false;
										that.$message.error(resTwo.msg);
									}
								})
							} else {
								that.DialogLoading = false;
								that.$message.error(res.msg);
							}
						})
					}
				})


			},
			showOrderDetail() {
				this.detailPopup = true;
				this.detail_orderNo = this.form.orderNo;
				this.detail_declaredValue = this.form.declaredValue;
				this.detail_destCountry = this.form.destCountry;
				if (this.form.destCountry == 'SA' && this.form.deliveryType == '2' && this.form.warehouseType == '5') {
					this.detail_destWhNo = this.SelfDestWhNo;
					this.detail_otherDest = this.SelfOtherDest;
				} else {
					this.detail_destWhNo = this.form.destWhNo;
					this.detail_otherDest = this.form.otherDest;
				}

				// 生成下载面单
				this.expressSheet();
				this.getAddress();
			},
			// 下载PDF面单
			expressSheet() {
				let that = this;
				this.PdfLoading = true;
				this.$http.get(
					"/toborder/download/waybill/" + this.form.orderNo
				).then(res => {
					if (res.code === 200) {
						this.url = res.data;
					} else {}
					that.PdfLoading = false;
				}).catch(err => {
					if (err.code != 500) {
						var waitMinute = Math.round(this.orderCaseNum / 60);
						this.createErrorInfo = this.$t('chinaWhB2BOrderPage.generateBoxStickerTipsPre') +
							waitMinute + '-' + (waitMinute + 1) + this.$t(
								'chinaWhB2BOrderPage.generateBoxStickerTipsNext');
					} else {
						this.$message.warning(err.msg);
					}

				})
			},
			// 下载paf
			getPdf() {
				window.open(this.url, "_blank");
			},
			//获取仓库地址
			getAddress() {
				this.$http.get("/toborder/system/dict/data/list?dictType=sys_szwh_address").then(res => {
					if (res.code == 200) {
						this.whAddress = res.rows[0].dictValue;
						this.whContactMan = res.rows[0].remark.split('###')[0];
						this.whContactPhone = res.rows[0].remark.split('###')[1];
					} else {
						this.$message.error("获取仓库地址失败");
					}
				})
			},

			//上传成功
			handlesuccess(files, fileList) {
				this.uploadloading = false;
				if (files.code == 200) {
					this.$message.success(this.$t('tipsInfo.uploadSuccess'));
					this.uploadData = files.data;
					this.dialogErrorVisible = true;
					this.uploadSuccess = true;
				} else if (files.code == 400) {
					this.uploadData = [];
					this.uploadSuccess = false;
					this.$message.warning(this.$t('chinaWhB2BOrderPage.uploadPacketFileDiffNumTips'));
				} else if (files.code == 500) {
					this.uploadData = [];
					this.uploadSuccess = false;
					this.$message.warning(files.msg);
				}
			},
			// 上传时触发
			beforeUpload(file) {
				var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
				const extension = testmsg === "xls";
				const extension2 = testmsg === "xlsx";
				if (!extension && !extension2) {
					this.$message.warning(this.$t('tipsInfo.uploadExcelTypeTips'));
				}
				return extension || extension2;
			},
			// 上传失败
			handleError(err, file, fileList) {
				this.uploadloading = false;
			},
			// 上传时
			handleProgress(event, file, fileList) {
				this.uploadloading = true;
				this.$message.warning(this.$t('tipsInfo.uploading'));
			},
			// 渲染row
			setSeaOrderClassName({
        row,
        index
      }){
				let className = '';
				if(row.reviewStatus == '审核未通过'){
					className = 'reviewFailed'
				}
				return className
			},
			//查询海运订单列表
			getSeaOrderList() {
				let query = this.seaQueryParams;
				this.loadingList = true;
				this.$http.post("/toborder/sea/order/list", query).then(res => {
					if (res.code == 200) {
						this.total = res.data.totalCount;
						this.loadingList = false;
						this.seaOrderList = res.data.list;
					} else {
						this.loadingList = false;
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.loadingList = false;
					this.$message.error(err.msg);
				})
			},
			//搜索海运订单
			handleSearchSea() {
				if (this.orderNumber != '') {
					this.seaQueryParams.awbNoList = this.strToArr(this.orderNumber);
				} else {
					this.seaQueryParams.awbNoList = [];
				}

				if (this.clientAwbNo != '') {
					this.seaQueryParams.clientAwbNoList = this.strToArr(this.clientAwbNo);
				} else {
					this.seaQueryParams.clientAwbNoList = [];
				}
				this.seaQueryParams.page = 1;
				this.getSeaOrderList();

			},
			//重置
			handleResetSea() {
				this.orderNumber = '';
				this.clientAwbNo = '';
				this.seaQueryParams = {
					awbNoList: [],
					clientAwbNoList: [],
					page: 1,
					limit: 10,
				}
				this.getSeaOrderList();
			},
			// 上传文件
			getExcelTwo(data) {
				this.formList.skuListLink = data;
				//修改upload的preview预览
				if (data != '') {
					let str = data.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			//创建海运订单按钮
			addSeaOrder() {
				this.title = this.$t('commonInfo.createSeaOrder');
				this.addOceanPopup = true;
				this.modifyOcean = false;
			},
			//修改海运订单按钮
			async handleUpdateOrder(row) {
				this.title = this.$t('commonInfo.modifySeaOrder');
				this.addOceanPopup = true;
				this.modifyOcean = true;
				this.customerOrderNo = row.clientAwbNo; //获取客户订单号，校验是否重复
				const res = await this.$http.get("/toborder/sea/order/info/" + row.id);
				if (res.code === 200) {
					this.getSeaOrderDestData(row.id);
					this.formList = res.data;
					let str = this.formList.skuListLink.split("~");
					this.fileTXTName = [{
						name: str[1],
						url: this.formList.skuListLink
					}]
				} else {
					this.$message.error(res.msg);
				}

			},
			// 添加海运订单多目的信息
			handleAddSeaDest() {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = false;
				this.addSeaOrderDestPopup = true;
				this.seaDestForm.destCountry = this.formList.destCountry;
			},
			// 获取海运订单多目的地列表
			async getSeaOrderDestData(id) {
				let query = {
					orderId: id
				}
				this.seaDestTableLoading = true;
				const res = await this.$http.post("toborder/sea/orderDest/list", query);
				if (res.code === 200) {
					this.seaOrderDestList = res.data;
					this.seaDestTableLoading = false;
				} else {
					this.seaDestTableLoading = false;
					this.$message.error(res.msg);
				}
			},

			// 返回订单服务类型
			returnDestType(type) {
				let text = '';
				switch (type) {
					case '1':
						text = 'FBA';
						break;
					case '2':
						text = 'FBN';
						break;
					case '5':
						text = 'OWH';
						break;
					case 'FBA':
						text = '1';
						break;
					case 'FBN':
						text = '2';
						break;
					case 'OWH':
						text = '5';
						break;
				}
				return text;
			},
			// 清空海运订单目的地信息
			handleClearSeaOrderDestInfo(val) {
				this.$confirm(this.$t('chinaWhB2BOrderPage.clearAllDestInfoTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						if (this.modifyOcean) { // 已提交数据删除需要调接口
							let arr = [];
							this.seaOrderDestList.forEach(item => {
								arr.push({
									destType: item.destType,
									id: item.id,
								})
							})
							this.deleteSeaOrderDestInfo(arr);
						} else { // 本地删除
							this.seaOrderDestList = [];
							this.isSa = val;
						}

					})
					.catch(() => {
						this.formList.destCountry = this.isSa;
					})
			},
			// 根据目的地信息删除
			deleteSeaOrderDestInfo(arr) {
				this.$http.post("toborder/sea/orderDest/delete", arr).then(res => {
					if (res.code == 200) {
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(this.formList.id);
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.seaDestLoading = false;
				})
			},
			// 点击查看目的地详情
			handleCheckDestInfo(row) {
				this.destDetailPopup = true;
				this.destDetailForm = row;
			},


			// 点击更新目的地信息
			handleUpdateDestInfo(row) {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = true;
				this.getSeaDestInfo(row);
				this.addSeaOrderDestPopup = true;
			},
			// 获取目的地信息
			getSeaDestInfo(row) {
				let that = this;
				this.seaDestForm.destType = this.modifyOcean ? this.returnDestType(row.destType) : row.destType;
				if (this.modifyOcean) { // 修改则需要记录当前目的地信息
					this.seaDestForm.oldDestType = this.seaDestForm.destType;
				}
				setTimeout(function() {
					that.seaDestForm.destWh = row.destWh;
					that.showSeaDestInfo = true;
					setTimeout(function() {
						that.seaDestForm.id = row.id;
						that.seaDestForm.orderId = row.orderId;
						that.seaDestForm.index = row.index; //标识
						that.seaDestForm.fbaNo = row.fbaNo; //FBA号
						that.seaDestForm.fbaTrackingNo = row.fbaTrackingNo; //FBA追踪编码
						that.seaDestForm.fbnNo = row.fbnNo; //ASN号
						that.seaDestForm.reservationNumber = row.reservationNumber; //预约号
						that.seaDestForm.reservationFile = row.reservationFile; //入仓文件
						that.seaDestForm.reservationTime = row.reservationTime; // 预约时间
						that.seaDestForm.destCountry = row.destCountry; //目的国家
						that.seaDestForm.destCity = row.destCity; //目的城市
						that.seaDestForm.destWh = row.destWh; //目的仓库
						that.seaDestForm.destWhAddress = row.destWhAddress; //目的送仓地址
						that.seaDestForm.consignee = row.consignee; //联系人
						that.seaDestForm.contactTel = row.contactTel; //联系人电话
						that.seaDestForm.remarks = row.remarks; //备注
						that.seaDestForm.pcsNum = row.pcsNum;//箱总PCS数
						that.seaDestForm.packageIndexString = row.packageIndexString; //箱序号
						if (row.reservationFile != '' && row.reservationFile != null) {
							//修改upload的preview预览
							let str = row.reservationFile.split("~");
							that.seaFbxPreName = [{
								name: str[1],
								url: row.reservationFile,
							}]
						}
					}, 100)
				}, 100)
			},
			// 点击删除目的地信息
			handleDeleteDestInfo(row) {
				this.$confirm(this.$t('tipsInfo.deleteRowTips'), this.$t('tipsInfo.warning'), {
						confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
						cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
						type: "warning"
					})
					.then(() => {
						// 修改订单删除目的地信息调后台接口，创建订单本地缓存增删
						if (this.modifyOcean) {
							let query = [{
								destType: row.destType,
								id: row.id,
							}]
							this.deleteSeaOrderDestInfo(query);
						} else {
							let indexs = this.seaOrderDestList.findIndex(
								item => item.index === row.index
							);
							this.seaOrderDestList.splice(indexs, 1);
						}
					})
					.catch(() => {})
			},
			// 获取选中仓库信息
			getSeaOrderDestInfo(val) {
				let that = this;
				that.seaDestForm.destWhAddress = '';
				that.seaDestForm.consignee = '';
				that.seaDestForm.contactTel = '';
				that.seaDestForm.destCity = '';
				setTimeout(function() {
					that.selectWhList.forEach(item => {
						if (item.dictValue == val) {
							if (that.seaDestForm.destType == '5') { //如果是海外仓，则获取字典存储的地址###联系人###联系电话信息
								// 自定义海外仓可编辑，其他私人地址不可编辑
								if (val != 'custom') {
									if (item.remark != '' && item.remark != null) {
										let info = item.remark.split('###');
										that.seaDestForm.destWhAddress = info[0];
										that.seaDestForm.consignee = info[1];
										that.seaDestForm.contactTel = info[2];
										if (that.seaDestForm.consignee == 'Fleetan-') {
											that.seaDestForm.consignee = that.seaDestForm.consignee + that
												.prefixAwbNo;
										}
										that.editSeaOtherDest = true; //不可编辑，直接填充
										that.editSeaOrderCity = true; // 编辑城市

										// 沙特海外仓仓库默认都在利雅得城市
										if (that.isSa == 1) {
											let list = that.saCityList.filter(item => item.dictValue ==
												'1');
											that.seaDestForm.destCity = list[0].dictLabel;
										} else { // 阿联酋暂时没有仓库，城市默认为空
											let list = that.saCityList.filter(item => item.dictValue ==
												'3');
											that.seaDestForm.destCity = list[0].dictLabel;
										}

									}
								} else {
									// 自定义海外仓可以填写地点信息
									that.editSeaOtherDest = false;
									that.editSeaOrderCity = false;
								}
							} else { // FBA NOON
								that.editSeaOtherDest = true;
								that.seaDestForm.destWhAddress = item.remark;
								// 沙特海外仓仓库默认都在利雅得城市
								if (that.isSa == 1) {
									let list = that.saCityList.filter(item => item.dictValue == '1');
									that.seaDestForm.destCity = list[0].dictLabel;
								} else { // 阿联酋暂时没有仓库，城市默认为空
									let list = that.saCityList.filter(item => item.dictValue == '3');
									that.seaDestForm.destCity = list[0].dictLabel;
								}
							}
						}
					})
				}, 300)
			},
			// 获取上传文件
			getSeaFBXInfo(data) {
				this.seaDestForm.reservationFile = data;
				if (data != '') {
					//修改upload的preview预览
					let str = data.split("~");
					this.seaFbxPreName = [{
						name: str[1],
						url: data,
					}]
				}
			},
			// 关闭添加海运订单目的地信息窗口
			handleCloseSeaOrderDestDialog() {
				this.seaDestForm = {
					id: '', //ID
					orderId: '', //订单ID
					index: '', //标识
					destType: '', //FBA NOON OWH
					fbaNo: '', //FBA号
					fbaTrackingNo: '', //FBA追踪编码
					fbnNo: '', //ASN号
					reservationNumber: '', //预约号
					reservationFile: '', //入仓文件
					reservationTime: '', // 预约送仓文件
					destCountry: '', // 目的国家
					destCity: '', //目的城市
					destWh: '', //目的仓库
					destWhAddress: '', //目的送仓地址
					consignee: '', //联系人
					contactTel: '', //联系人电话
					remarks: '', //备注
					packageIndexString: '',
					pcsNum:'',// 当前目的地箱子总PCS数
					cartonIndexStr: '',
					seaOrderDestPackageInfoDto: { // 绑定箱信息
						orderType: '', //订单类型
						// 	packageId:'',//ID
						// 	packageAwbNo:'',//系统箱号
						// 	clientPackageAwbNo:'',//客户箱号
						packageIndex: '', //箱编号 创建订单前没有箱号需要用到
						// 	remarks:'',//备注
					},
				}
				this.editSeaDest = false;
				this.seaReservationFile = ''; //预约文件
				this.seaFbxPreName = []; // 上传文件预览
				this.showSeaDestInfo = false;
				this.editSeaOtherDest = false;
				this.editSeaOrderCity = false;
				this.addSeaOrderDestPopup = false;
			},
			// 提交海运订单目的地信息
			submitSeaOrderDestInfo() {
				// FBA和FBN 需要上传送仓文件
				if (this.seaDestForm.destType == '1' || this.seaDestForm.destType == '2') {
					if (this.seaDestForm.reservationFile == '') {
						return this.$message.warning(this.$t('tipsInfo.submitOrderFBXFileTips'));
					}
				}
				// 判断箱序号填写
				let strArr = this.seaDestForm.packageIndexString.split(',');
				let cartonStr = '',
					overMax = false;
				strArr.forEach(item => {
					if (item != '' && item != null) {
						if (item.indexOf('-') != -1) { // 范围
							for (var i = parseInt(item.split('-')[0]); i <= parseInt(item.split('-')[1]); i++) {
								cartonStr = cartonStr + i + ',';
								if (i > this.formList.packageAmount) {
									overMax = true;
								}
							}
						} else { // 单个箱序号
							cartonStr = cartonStr + item + ',';
							if (item > this.formList.packageAmount) {
								overMax = true;
							}
						}
					}
				})
				if (overMax) {
					return this.$message.warning(this.$t('chinaWhB2BOrderPage.overCartonAmountTips'))
				}
				let reg = /,$/gi;
				cartonStr = cartonStr.replace(reg, '');
				this.seaDestForm.cartonIndexStr = cartonStr;

				this.$refs["seaDestForm"].validate(valid => {
					if (valid) {
						// 订单创建则本地缓存目的地信息，提交订单后一起提交目的地信息
						if (!this.modifyOcean) {
							if (this.editSeaDest) { // 修改
								let index = this.seaOrderDestList.findIndex(item => item.index == this.seaDestForm
									.index);
								this.$set(this.seaOrderDestList, index, this.seaDestForm);
							} else { // 增加
								let nowTime = new Date().getTime();
								this.seaDestForm.index = nowTime;
								this.seaOrderDestList.push(this.seaDestForm);
							}
							this.handleCloseSeaOrderDestDialog();
						}
						// 修改订单则每次修改目的地信息都要请求后台接口
						else {
							if (this.editSeaDest) { // 修改
								this.updateSeaOrderDestInfo(this.seaDestForm);
							} else { // 增加
								this.createSeaOrderDestInfo(this.seaDestForm);
							}
						}

					}
				})
			},
			// 修改目的地信息
			updateSeaOrderDestInfo(form) {
				//新老目的地类型
				let obj = JSON.parse(JSON.stringify(form));
				obj.awbNo = this.formList.awbNo;
				obj.newDestType = this.returnDestType(obj.destType);
				obj.destType = this.returnDestType(obj.oldDestType);
				obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
				obj.seaOrderDestPackageInfoDto.orderType = 'SEA';

				this.seaDestLoading = true;
				this.$http.post("toborder/sea/orderDest/update", obj).then(res => {
					if (res.code == 200) {
						this.seaDestLoading = false;
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(obj.orderId);
						this.handleCloseSeaOrderDestDialog();
					} else {
						this.$message.error(res.msg);
						this.seaDestLoading = false;
					}
				}).catch(err => {
					this.seaDestLoading = false;
					this.$message.error(err.msg);
				})
			},
			// 增加目的地信息
			createSeaOrderDestInfo(form) {
				let obj = JSON.parse(JSON.stringify(form));
				obj.awbNo = this.formList.awbNo;
				obj.orderId = this.formList.id;
				obj.destType = this.returnDestType(obj.destType);
				obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
				obj.seaOrderDestPackageInfoDto.orderType = 'SEA';
				let arr = [];
				arr.push(obj);
				this.seaDestLoading = true;
				this.$http.post("toborder/sea/orderDest/create", arr).then(res => {
					if (res.code == 200) {
						this.seaDestLoading = false;
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.getSeaOrderDestData(obj.orderId);
						this.handleCloseSeaOrderDestDialog();
					} else {
						this.$message.error(res.msg);
						this.seaDestLoading = false;
					}
				}).catch(err => {
					this.seaDestLoading = false;
					this.$message.error(err.msg);
				})
			},

			//提交海运订单
			submitForm() {
				if (this.formList.skuListLink == '') { //上传清单（必填）
					return this.$message.warning(this.$t('tipsInfo.submitOrderSkuListFileTips'));
				}
				// 提交订单需要判断目的信息是否有同个箱有多个派送地址
				// 修改订单不判断，目的地信息已单独调用后台接口
				if (!this.modifyOcean) {
					let str = '';
					if (this.seaOrderDestList.length > 0) {
						this.seaOrderDestList.forEach(item => {
							str += item.cartonIndexStr + ','
						})
						let arr = str.split(',');
						let newArr = new Set(arr) // 去重
						if (arr.length != newArr.size) {
							return this.$message.warning(this.$t('chinaWhB2BOrderPage.repeatCartonTips'))
						}
					}
				}
				let data = {
					id: this.formList.id,
					clientAwbNo: this.formList.clientAwbNo,
					packageAmount: this.formList.packageAmount,
					cnConsignorWh: this.formList.cnConsignorWh,
					destType: this.formList.destType,
					destCountry: this.formList.destCountry,
					skuListLink: this.formList.skuListLink,
					customsDeclarationFlag:this.formList.customsDeclarationFlag,
				}
				this.$refs["formList"].validate(valid => {
					if (valid) {
						let mod = this.modifyOcean;
						if (mod) {
							this.ocanLoading = true;
							this.$http.post("/toborder/sea/order/update", data).then(res => {
								if (res.code == 200) {
									this.ocanLoading = false;
									this.$message.success(this.$t('B2BOrder.succeededOceanOrder'));
									this.addOceanPopup = false;
									this.getSeaOrderList();
								} else {
									this.$message.error(res.msg);
									this.ocanLoading = false;
								}
							}).catch(err => {
								this.ocanLoading = false;
								this.$message.error(err.msg);
							})
						} else {
							this.ocanLoading = true;
							this.$http.post("/toborder/sea/order/create", this.formList).then(res => {
								if (res.code == 200) {
									if (this.seaOrderDestList.length > 0) {
										this.submitSeaDestData(res.data);
									} else {
										this.ocanLoading = false;
										this.$message.success(this.$t('B2BOrder.addOceanOrder'));
										this.addOceanPopup = false;
										this.handleCloseSeaOrderDialog();
										this.getSeaOrderList();
									}
								} else {
									this.$message.error(res.msg);
									this.ocanLoading = false;
								}
							}).catch(err => {
								this.ocanLoading = false;
								this.$message.error(err.msg);
							})
						}

					}
				})
			},
			// 提交海运订单目的地信息数据
			submitSeaDestData(data) {
				let arr = [];
				this.seaOrderDestList.forEach(item => {
					let obj = JSON.parse(JSON.stringify(item));
					switch (obj.destType) {
						case '1':
							obj.destType = 'FBA';
							break;
						case '2':
							obj.destType = 'FBN';
							break;
						case '5':
							obj.destType = 'OWH';
							break;
					}
					obj.awbNo = data.awbNo;
					obj.orderId = data.orderId;
					obj.seaOrderDestPackageInfoDto.packageIndex = obj.cartonIndexStr;
					obj.seaOrderDestPackageInfoDto.orderType = 'SEA';
					arr.push(obj);
				})
				this.$http.post("/toborder/sea/orderDest/create", arr).then(res => {
					if (res.code == 200) {
						this.ocanLoading = false;
						this.$message.success(this.$t('B2BOrder.succeededOceanOrder'));
						this.addOceanPopup = false;
						this.handleCloseSeaOrderDialog();
						this.getSeaOrderList();
					} else {
						this.ocanLoading = false;
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.ocanLoading = false;
					this.$message.error(err.msg);
				})

			},

			//客户订单号失去焦点事件
			async onInputBlur() {
				if (this.formList.clientAwbNo != "" && this.formList.clientAwbNo != null) {
					let reg = /^[a-zA-Z0-9_-]+$/
					if (reg.test(this.formList.clientAwbNo)) {
						if (this.modifyOcean == false) {
							const res = await this.$http.get(
								`/toborder/sea/order/verification?clientAwbNo=${this.formList.clientAwbNo}`);
							if (res.code == 200 && res.data == false) {
								this.$message.error(this.$t('B2BOrder.orderNoRepeat'));
							}
						}

						if (this.modifyOcean == true && this.formList.clientAwbNo != this.customerOrderNo) {
							const res = await this.$http.get(
								`/toborder/sea/order/verification?clientAwbNo=${this.formList.clientAwbNo}`);
							if (res.code == 200 && res.data == false) {
								this.$message.error(this.$t('B2BOrder.orderNoRepeat'));
							}
						}
					} else {
						this.$message.warning(this.$t('commonInfo.numberLimit'));
					}
				}
			},
			//查看轨迹
			async orderTracking(row) {
				this.awbNo = row.awbNo;
				this.activiData = [];
				this.leaveTracking = null;
				this.arriveTracking = null;
				const res = await this.$http.get("/toborder/sea/tracking/info/" + row.awbNo);
				if (res.code == 200) {
					this.popup = true;
					// this.activiData = res.data;
					if (res.data && res.data.length > 0) {
						// 显示预计开船或预计到港需要判断轨迹是否已经存在已起航或到港的真实轨迹
						let trueLeave = res.data.filter(item => item.trackingStatus == 300);
						let trueArrive = res.data.filter(item => item.trackingStatus == 320);

						res.data.forEach(item => {
							// 预计开船和预计到港轨迹
							switch (item.trackingStatus) {
								case 230: //预计开船
									if (trueLeave.length == 0) { // 已有起航轨迹就不需要显示预计开船时间轨迹
										this.leaveTracking = item;
									}
									break;
								case 310: //预计到港
						   if (trueArrive.length == 0) { // 已有到港轨迹就不需要显示预计到港时间轨迹
						 			this.arriveTracking = item;
									}
									break;
								default:
									this.activiData.push(item);
									break;
							}
						})
					}
				}
			},
			handleConfirm() {
				this.popup = false;
			},
			//查看详情
			async handleView(row) {
				this.productPopup = true;
				//订单详情
				const res = await this.$http.get("/toborder/sea/order/detail/" + row.id);
				if (res.code == 200) {
					this.orderDetailsList = res.data;
				}
				this.awbNumber = row.awbNo;
				this.handlebox();
			},
			async handlebox() {
				//箱信息
				const resTwo = await this.$http.get(
					`/toborder/sea/package/list?awbNo=${this.awbNumber}&page=${this.params.page}&limit=${this.params.limit}`
					)
				if (resTwo.code == 200) {
					this.boxInformationList = resTwo.data.list;
					this.total = resTwo.data.totalCount;
				}
			},
			// 查看目的地信息
			handleCheckOrderDestInfo(row) {
				// 查询该订单的目的地信息
				this.getSeaOrderDestData(row.id);
				this.modifyOcean = true;
				this.isSa = row.destCountry == '沙特' ? '1' : '2';
				this.formList.packageAmount = row.packageAmount;
				this.formList.awbNo = row.awbNo;
				// 获取订单信息
				this.checkDestInfoDetail = {
					id: row.id,
					awbNo: row.awbNo,
					destCountry: row.destCountry,
					packageAmount: row.packageAmount,
					cnConsignorWh: row.cnConsignorWh,
					trackingStatusValue: row.trackingStatusValue,
				}
				this.checkDestInfoPopup = true;
			},
			//添加目的地信息
			handleAddSeaDetailDest() {
				this.seaDestTitle = this.$t('chinaWhB2BOrderPage.seaOrderDestTitle');
				this.editSeaDest = false;
				this.addSeaOrderDestPopup = true;
				this.formList.awbNo = this.checkDestInfoDetail.awbNo;
				this.formList.id = this.checkDestInfoDetail.id;
				this.seaDestForm.destCountry = this.checkDestInfoDetail.destCountry == '沙特' ? '1' : '2';
				this.formList.packageAmount = this.checkDestInfoDetail.packageAmount;
			},
			// 关闭查看目的地信息窗口
			handleCloseCheckDestDialog() {
				this.seaOrderDestList = [];
				this.checkDestInfoDetail = {
					id: '',
					awbNo: '',
					destCountry: '',
					packageAmount: '',
					cnConsignorWh: '',
					trackingStatusValue: 0,
				}
				this.modifyOcean = false;
				this.formList.packageAmount = '';
				this.formList.awbNo = '';
				this.checkDestInfoPopup = false;
			},
			// 下载面单
			handleDownloadAwb(row) {
				window.open(row.awbUrl);
			},
			// 下载空运清单模板
			handleDownloadAirTemplate() {
				let url = '';
				switch (this.form.warehouseType) {
					case '1':
						url = this.airTemplate.fba;
						break
					case '2':
						url = this.airTemplate.noon;
						break;
					case '5':
						url = this.airTemplate.owh;
						break;
				}
				window.open(url);
			},
			//下载海运清单模板
			handleDownloadTemplate() {
				window.open(this.detailedUrl);
			},
			// 查看海运订单审核不通过原因
			handleCheckSeaOrderFailedReason(row){
				this.checkSeaOrderFailedPopup = true;
				this.seaOrderRow = row;
			},
			// 关闭窗口
			handleCloseSeaOrderFailedReasonDialog(){
				this.checkSeaOrderFailedPopup = false;
				this.seaOrderRow = {};
			},
			// 显示解析文件失败原因，优化显示
			returnParsingFilesFailedReason(){
				let html = '';
				html += "<label>清单文件解析失败,请检查清单文件，请注意下列事项：</label>" +
				"        <label>1、图片不要嵌入式，不能超出单元格</label>" +
				"        <label>2、单箱产品数量、总数量、申报单价、申报总金额四列数据请不要为空，若暂时没有则填写0</span>" +
				"        <label>3、检查文件是否有空白行以及特殊符号</label>"
				return html;
			},
			// 跳转到修改订单窗口
			handleTurnToModifyOrder(){
				this.checkSeaOrderFailedPopup = false;
				this.handleUpdateOrder(this.seaOrderRow);
			},
			
			//查看POD
			handlePOD(list) {
				this.imgList = [];
				var that = this;
				if (list.length > 0) {
					this.showImg = true;
					list.forEach(item => {
						that.imgList.push(item);
					})
				} else {
					this.$message.error("暂无数据");
				}
				// console.log(this.PodPreList);
			},
			// 分页条数
			siChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.handlebox();
			},
			// 分页页数
			curChange(page) {
				this.params.page = page;
				this.handlebox();
			},

			// 点击关闭 数据重置
			handleCloseSeaOrderDialog() {
				this.addOceanPopup = false;
				this.formList = {
					clientAwbNo: '',
					packageAmount: '',
					cnConsignorWh: '',
					destType: '',
					destCountry: '',
					skuListLink: '',
					customsDeclarationFlag:false,//是否报关
				};
				this.isSa = '';
				this.seaOrderDestList = [];
				this.fileTXTName = [];
				this.modifyOcean = false;
			},
			// 分页条数
			sizeChange(size) {
				this.seaQueryParams.limit = size;
				this.seaQueryParams.page = 1;
				this.getSeaOrderList();
			},
			// 分页页数
			currentChange(page) {
				this.seaQueryParams.page = page;
				this.getSeaOrderList();

			},
			
			// 空运批量下单
			// 下载模板
			handleDownloadModel(){
				window.open(this.batchCreateOrderModulUrl);
			},
			// 上传成功
			handleSuccessMutipleOrder(files, fileList){
				this.uploadloading = false;
				if(files.code == 200){
				  if(files.data.success){
				    this.$message.success("上传成功 !");
						// 判断所有数据是否都正确
						this.isSuccessData = true;
						let res = files.data.uploadTobOrderErrorInfoList;
						res.forEach(item => {
							if(item.errorMessage != null){
								this.isSuccessData = false;
							}
						})
				  }else{
				    this.$message.error("上传失败!");
				    this.isSuccessData = false;
				  }
				  this.multipleUploadData = files.data.uploadTobOrderErrorInfoList;
				}else{
					this.$message.error(files.msg);
				}
			},
			// 关闭窗口
			handleBatchCreateOrderDialogClose(){
				this.batchCreateOrderPopup = false,
				this.isSuccessData = false;
				this.multipleUploadData = [];
			},
			// 提交数据
			submitMultipleCreateOrder(){
				this.multipleCreateOrderLoading = true;
				this.$http.post("/toborder/batchCreate/" + this.clientId, this.multipleUploadData).then(res => {
					if (res.code == 200) {
						this.multipleCreateOrderLoading = false;
						this.$message.success(this.$t('tipsInfo.operationSuccess'));
						this.handleBatchCreateOrderDialogClose();
						this.getlist();
					} else {
						this.multipleCreateOrderLoading = false;
						this.$message.error(res.msg);
					}
				}).catch(err => {
					this.multipleCreateOrderLoading = false;
					this.$message.error(err.msg);
				})
			},
			
		},
		created() {
			let customInfo = JSON.parse(window.sessionStorage.getItem("customInfo"));
			this.prefixAwbNo = customInfo.prefixAwbNo;
			this.clientId = customInfo.clientId;
			this.frontOrafter = customInfo.frontOrafter;
			this.clientEnName = customInfo.clientEnName;
			this.isSpecial = customInfo.isSpecial;
			this.userName = customInfo.clientShortName;

			let token = window.sessionStorage.getItem("token");
			this.headers = {
				Authorization: token,
			}

			this.getlist();
			this.getDict(); //获取页面需要的数据字典
			this.getKindNameList();
		},
		mounted() {

		}
	};
</script>
<style scoped>
	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}

	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}

	.listHeader span {
		display: inline-block;
		float: left;
	}

	.app-container {
		padding: 10px;
	}

	.textareaNo {
		width: 250px;
	}

	.textareaNo .el-textarea__inner {
		max-height: 35px !important;
	}

	.productTip {
		font-size: 12px;
		display: inline-block;
		/* float: right;
	  height: 20px;
	  line-height: 20px;
	  width: 450px;
		margin-right: 6%; */
		color: #b75b5b;
	}

	.SaTip {
		font-size: 12px;
		display: inline-block;
		float: right;
		height: 20px;
		line-height: 20px;
		width: 94%;
		margin-right: 6%;
		color: #b75b5b;
	}

	.fileTip {
		display: inline-block;
		width: 200px;
		height: 35px;
		line-height: 35px;
		font-size: 14px;
		float: right;
		margin: 10px;
		color: #b75b5b;
	}

	.stepthrees {
		height: 100%;
		line-height: 45px;
	}

	.three>span {
		font-size: 16px;
	}

	.stepthree {
		padding: 20px;
	}

	.orderDetails {
		margin-bottom: 10px;
		font-size: 20px;
	}

	.el-divider--horizontal {
		margin: 30px 0 !important;
	}

	.stepthree div>span {
		display: inline-block;
		width: 100%;
		text-align: left;
	}

	/* .dialog_orderNo {
		width: 100%;
		height: 50px;
		line-height: 26px;
	}

	.dialog_orderNo label {
		float: left;
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}
	.dialog_orderNo label:nth-child(2){
	  float: right;
	  color: #5981c9;
	  font-size: 18px;
	} */
	.dialog_orderNo {
		width: 100%;
		height: 50px;
		line-height: 40px;
		margin-bottom: 5px;
	}

	.dialog_orderNo label {
		margin-left: 40px;
		font-weight: bold;
		border-radius: 8px;
		font-size: 20px;
		padding: 9px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}

	.dialog_extraInfo {
		width: 100%;
		padding: 15px 25px;
		margin-bottom: 5px;
	}

	.dialog_extraInfo label {
		margin: 10px 15px;
		font-weight: bold;
		border-radius: 8px;
		color: #5981c9;
		font-size: 18px;
		padding: 5px 15px;
		border: 1px solid #EBEEF5;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}

	.trackingOpt {
		display: inline-block;
		width: 500px;
		margin: 4px 15px;
		padding: 8px 10px;
		font-size: 14px;
		color: #ededed;
		border-radius: 6px;
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
	}

	.trackingOpt i {
		font-size: 26px;
		display: block;
		float: left;
		width: 40px;
		height: 50px;
		line-height: 50px;
	}

	.trackingOpt span {
		width: 440px;
		line-height: 24px;
		display: block;
		float: left;
	}

	.leave {
		background-color: #01AAED;
		border: 1px solid #01AAED;
	}

	.arrive {
		background-color: #1e8f86;
		border: 1px solid #1e8f86;
	}

	.pageBottom {
		padding: 20px;
	}

	/deep/ .expand .el-table__expand-column .cell {
		display: none !important;
	}

	.expandDataRow {
		border: 1px solid #a9c5e2;
		margin: 10px auto;
		border-radius: 5px;
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	}

	/deep/ .el-table__expand-icon>.el-icon {
		font-size: 18px;
	}

	/deep/ .el-table__expand-icon {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	/deep/ .el-table__expand-icon .el-icon-arrow-right:before {
		content: '\e791';
	}

	/deep/ .el-table__expand-icon--expanded .el-icon-arrow-right:before {
		content: '\e790';
	}

	/* 子订单号 */
	.dialog_subOrderNo {
		width: 100%;
		height: 40px;
		line-height: 30px;
	}

	.dialog_subOrderNo label {
		margin-left: 20px;
		border-radius: 6px;
		font-size: 16px;
		padding: 5px 10px;
	}

	/deep/ .batchInfoItem {
		margin-left: 10px;
	}

	/deep/ .batchInfoItem .el-badge__content.is-fixed.is-dot {
		right: 0px;
		top: 6px;
	}

	/* 更多操作中分批信息样式 */
	/deep/ .batchInfoItem1 .el-badge__content.is-fixed.is-dot {
		right: 16px;
		top: 6px;
	}

	.el-icon-s-promotion {
		font-size: 22px;
	}

	.el-icon-ship {
		font-size: 22px;
	}

	.cartonIndexDivide {
		width: 40px;
		margin: 2px 10px;
		height: 2px;
		background-color: #a1a1a1;
		display: inline-block;
	}

	.seaOrderDestDetail {
		font-size: 18px;
		margin-bottom: 20px;
	}

	.seaOrderDestDetail .el-col {
		height: 40px;
		line-height: 40px;
	}

	.seaOrderDestDetail label {
		font-weight: bold;
	}

	.seaOrderDestDetail .el-col:nth-child(odd) {
		text-align: right;
	}

	.seaOrderDestDetail .el-col:nth-child(even) {
		text-indent: 20px;
	}
	/deep/ .reviewFailed{
		background-color: #ffe9e9;
	}
	
	.skuFileTip{
		width: 690px;
		margin-left: 20px;
		margin: 10px 0;
		background-color: #fffdd6;
		border-radius: 4px;
		padding: 5px 10px;
	}
	.skuFileTip h4{
		margin: 0;
		line-height: 30px;
	}
	.skuFileTip i{
		margin: 0 5px;
	}
	.skuFileTip span{
		display: block;
		height: 20px;
		line-height: 20px;
		text-indent: 20px;
	}
	.skuFileTip label{
		display: block;
		height: 20px;
		line-height: 20px;
		text-indent: 40px;
		font-weight: bold;
	}
	/deep/.parsingFilesFailedTips label{
		display: block;
	}
</style>
